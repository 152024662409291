import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import TabBaseComponent from './tab-base';
import MediaEvents from 'ln-ember-toolkit/mixins/media-events';

function taskGroupProperty(filter, filterFn) {
  return computed('filter', 'tasks.[]', function() {

    if (this.tasks && ['all', filter].indexOf(this.filter) !== -1) {
      return this.tasks
        .filter(filterFn)
        .sortBy('end_date');
    }

    return [];
  });
}

export default TabBaseComponent.extend(MediaEvents, {

  store: service(),

  state: service(),

  classNames: [
    'communicator-tab-tasks-component'
  ],

  classNameBindings: [
    'hasAnyOpenPopup'
  ],

  tasks: null,

  isLoading: true,

  hasAnyOpenPopup: false,

  filter: 'all',

  urgentTasks: taskGroupProperty('urgent', (task) => task.get('isCritical')),

  todayTasks: taskGroupProperty('today', (task) => task.get('toDoToday')),

  comingTasks: taskGroupProperty('coming', (task) => task.get('daysLeft') > 0),

  hasNoEntries: computed('urgentTasks.[]', 'todayTasks.[]', 'comingTasks.[]', function() {
    return (
      this.get('urgentTasks.length')
      + this.get('todayTasks.length')
      + this.get('comingTasks.length')
    ) === 0;
  }),

  didInsertElement() {
    this._super(...arguments);

    this.loadData();
  },

  actions: {
    infoDidChange(open) {
      this.set('hasAnyOpenPopup', open);
    }
  },
  loadData() {
    const user = this.get('state.session.user_id');
    if (user) {
      this.set('isLoading', true);
      this.store
        .query('vdc/task', { status: 'Open', user, embed: ['author'] })
        .then((tasks) => {
          if (this.isDestroyed) { return; }
          this.set('tasks', tasks.filter((task) => task.get('isOpen')));
          this.set('isLoading', false);
        })
        .catch(() => {
          if (this.isDestroyed) { return; }
          this.set('isLoading', false);
        });
    }
  }

});
