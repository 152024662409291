import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';
import {
  isSupportedBrowser,
  formatSupportedBrowsers
} from '../utils/browser';
import injectConfig from '../utils/inject-config';

export default Controller.extend({

  state: service(),

  clientTheme: service(),

  microMenu: service(),

  init() {
    this._super(...arguments);

    // TODO is there a better solution for this?
    // Set Class `resizing` to disable animations on resizing
    let resizeTimer;
    window.addEventListener('resize', run.bind(this, () => {
      clearTimeout(resizeTimer);
      document.body.classList.add('resizing');
      resizeTimer = setTimeout(() => document.body.classList.remove('resizing'), 10);
    }));

    const onOrientationChange = () => {
      // Set the body height, so that mobile browsers don't overlay content with
      // their UI.
      document.body.style.height = '100vh';
      if (window.innerHeight !== document.body.offsetHeight) {
        document.body.style.height = window.innerHeight;
      }
    };

    window.addEventListener('resize', onOrientationChange);
    window.addEventListener('orientationchange', onOrientationChange);
    onOrientationChange();
  },

  config: injectConfig(),

  browserIsSupported: computed(function() {
    const browsers = this.get('config.supportedBrowsers');
    return !browsers || isSupportedBrowser(browsers);
  }),

  browserSupportedMsg: computed(function() {
    const browsers = this.get('config.supportedBrowsers') || [];
    return formatSupportedBrowsers(browsers);
  })

});
