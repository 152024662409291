import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { action } from '@ember/object';

export default Component.extend({

  navigation: service(),

  state: service(),

  hoveredItem: null,

  classNames: [
    'communicator-tab-applications-component'
  ],

  close: () => {},

  itemHiglight: action(function(item, higlighted) {
    this.set('hoveredItem', higlighted ? item : null);
  }),

  removeErrorMessage: action(function() {
    this.set('state.applicationsError', null);
  }),

  itemClick: action(function({ url }) {
    const { navigation } = this;
    if (navigation.belongsToExternalApp(url)) {
      // Keep Communicator open when eternal Apps are opened
      navigation.openUrl(url);
    } else {
      this.close();
      later(() => navigation.openUrl(url), 500);
    }
  })
});
