import { inject as service } from '@ember/service';
import EmberObject, { computed } from '@ember/object';
import { toLabel } from 'ln-ember-toolkit/utils/string-utils';
import injectConfig from 'ln-liga-os/utils/inject-config';
import { loc } from '@ember/string';

export default EmberObject.extend({

  rights: service(),

  state: service(),

  config: injectConfig(),

  communicator: service(),

  identifier: null,

  activateController: false,

  hasRight: false,

  name: computed('identifier', function() {
    return toLabel(this.identifier);
  }),

  title: computed('identifier', function() {
    if (this.identifier === 'settings') {
      return this.get('state.user.fullName');
    }

    // return this.name.loc();
    return loc(this.name);
  }),

  hasAccess: computed('identifier', 'hasRight', function() {
    return this.hasRight && !this.get(`config.disable.communicator_${this.identifier}`);
  }),

  badge: computed('communicator.badges', function() {
    return this.get(`communicator.badges.${this.identifier}`);
  }),

  init() {
    this._super(...arguments);

    if (!this.hasRight) {
      const { identifier } = this;
      this.rights.can('OTHER', `ligaos.communicator.${identifier}.read`).then((permission) => {
        this.set('hasRight', permission);
      });
    }
  }
});
