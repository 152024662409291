import Component from '@ember/component';
import { throttle, debounce } from '@ember/runloop';
import MediaEvents from 'ln-ember-toolkit/mixins/media-events';
import $ from 'jquery';

export default Component.extend(MediaEvents, {
  classNames: [
    'contact-list-collection-component',
    'fade-in-content'
  ],

  classNameBindings: [
    'isScrolling',
    'hasAnyOpenPopup'
  ],

  columnWidth: 300,

  rowHeight: 60,

  scrollLeft: 0,

  scrollTop: 0,

  hasAnyOpenPopup: false,

  // rowHeight for the desktop version
  desktopRowHeight: 60,

  // rowHeight for the mobile version
  mobileRowHeight: 80,

  // Number of contacts left on the bottom before loading more data
  loadMoreThreshold: 50,

  isScrolling: false,

  loadNextPage: () => {},

  actions: {

    scrollChange(scrollLeft, scrollTop) {
      this.set('isScrolling', true);
      debounce(this, this.set, 'isScrolling', false, 150);

      if (this.scrollTop < scrollTop) {
        const collection = $('.contacts-collection > div', this.element);
        if (scrollTop > collection.get(0).scrollHeight - 2500) {
          throttle(this, this.loadNextPage, 200);
        }
      }

      this.set('scrollLeft', scrollLeft);
      this.set('scrollTop', scrollTop);
    },

    onPopupChange(open) {
      this.set('hasAnyOpenPopup', open);
    }
  }
});
