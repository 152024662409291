import Component from '@ember/component';

export default Component.extend({
  classNames: [
    'communicator-error-message-component'
  ],

  remove: () => {},

  actions: {
    remove() {
      this.remove();
    }
  }
});
