import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({

  state: service(),

  beforeModel() {
    if (this.get('state.session')) {
      return this.transitionTo('/!');
    }
  },

  activate() {
    // In some very rare cases appLoaded is set to true when the user lands on the login screen
    // That leads to a missing class name in the application view which messes up the login layout
    // comes from login route, initially commited by JG
    this.state.setProperties({
      appLoaded: false,
      appLocation: null
    });
    this.state.resetTitle();
  },

  setupController(controller) {
    controller.setProperties({
      username: this.get('config.login.username'),
      password: this.get('config.login.password'),
      showPasswordReset: false,
      showPasswordResetRequested: false
    });
  }
});
