import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

const conditions = [
  {
    label: 'At least 8 characters'.loc(),
    rule: 'lengthCondition'
  },
  {
    label: 'One uppercase character'.loc(),
    rule: 'uppercaseCondition'
  },
  {
    label: 'One lowercase character'.loc(),
    rule: 'lowecaseCondition'
  },
  {
    label: 'One number or special character'.loc(),
    rule: 'specialCharCondition'
  }
];
const rules = conditions.map(({ rule }) => rule);

export default Component.extend({

  api: service(),

  state: service(),

  clientTheme: service(),

  storage: service(),

  flashMessages: service(),

  showResetDone: false,

  password: null,

  confirmPassword: null,

  token: null,

  conditions,

  goToLogin: () => {},

  lengthCondition: computed('password', function() {
    return (this.password || '').length >= 8;
  }),

  uppercaseCondition: computed('password', function() {
    return /[A-Z]/.test(this.password || '');
  }),

  lowecaseCondition: computed('password', function() {
    return /[a-z]/.test(this.password || '');
  }),

  specialCharCondition: computed('password', function() {
    return /[\d#$()*+,-/:;=?@[\]^_]/.test(this.password || '');
  }),

  allRulesMet: computed(...rules, function() {
    return rules.every((rule) => this.get(rule));
  }),

  passwordsMatch: computed('password', 'confirmPassword', function() {
    return (this.password || '').length && this.password === this.confirmPassword;
  }),

  canResetPassword: computed('allRulesMet', 'passwordsMatch', function() {
    return !!(this.allRulesMet && this.passwordsMatch);
  }),

  actions: {
    resetPassword() {
      this.api
        .partialUpdate('myliga', ['security', 'password_resets'], { token: this.token, password: this.password })
        .then(() => {
          this.set('showResetDone', true);
        })
        .catch((error) => {
          this.flashMessages.addErrorMessages(error);
        });
    },

    goToLogin() {
      this.goToLogin();
    }
  }

});
