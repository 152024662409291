import { alias } from '@ember/object/computed';
import ItemBase from './item-base';

export default ItemBase.extend({
  tagName: 'span',

  classNames: [
    'contact-list-item-subhead-component',
    'subhead'
  ],

  subhead: alias('item.text')
});
