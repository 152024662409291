import Component from '@ember/component';
import { empty } from '@ember/object/computed';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({

  navigation: service(),

  classNames: [
    'task-list-component'
  ],

  classNameBindings: [
    'type',
    'hasOpenPopup',
    'isEmpty'
  ],

  infoDidChange: () => {},

  close: () => {},

  isEmpty: empty('taskList'),

  hasOpenPopup: computed('taskListItems.@each.hasOpenPopup', function() {
    return Boolean(this.taskListItems.findBy('hasOpenPopup'));
  }),

  type: computed('title', function() {
    return this.title.toLowerCase();
  }),

  taskListItems: computed('taskList', function() {
    return (this.taskList || []).map((task) => {
      return { hasOpenPopup: false, task };
    });
  }),

  actions: {
    toggleInfos(item) {
      set(item, 'hasOpenPopup', !get(item, 'hasOpenPopup'));
      this.infoDidChange(get(item, 'hasOpenPopup'));
    },

    closeInfos(item) {
      set(item, 'hasOpenPopup', false);
    },

    openTask(item) {
      const taskId = get(item, 'task.id');
      const contextType = item.task.get('context_type');
      const resourceName = contextType === 'Project' ? 'ProjectTask' : 'EventTask';

      if (this.navigation.canOpenResource(resourceName, taskId)) {
        this.navigation.openResource(resourceName, taskId);
        this.close();
      }
    }
  }
});
