window.global = window; // @see: https://github.com/ef4/ember-auto-import/issues/218#issuecomment-500539712
import browser from 'bowser';

export function isSupportedBrowser(supportedBrowsers) {
  if (!supportedBrowsers || supportedBrowsers.length === 0) {
    return true;
  }

  return Boolean(Object.keys(supportedBrowsers).find((key) => {
    const version = supportedBrowsers[key];
    return browser.name.toLowerCase() === key && browser.version >= version;
  }));
}

export function formatBrowserName(key, version) {

  let name = key;

  if (key === 'internet explorer') {
    name += ` ${version}`;
  }

  return name
    .split(' ')
    .map((word) => {

      if (word === 'iphone' || word === 'ipad') {
        return word.replace(/^ip/, 'iP');
      }

      return word.replace(/^./, (c) => c.toUpperCase());
    })
    .join(' ');
}

export function formatSupportedBrowsers(supportedBrowsers) {
  return Object.keys(supportedBrowsers)
    .map((key) => formatBrowserName(key, supportedBrowsers[key]))
    .sort((a, b) => a.localeCompare(b))
    .join(', ')
    .replace(/, ([^,]*)$/, ' and $1');
}
