import HashLocation from '@ember/routing/hash-location';

export default HashLocation.extend({

  implementation: 'custom-hash',

  replaceURL(path) {
    const url = new URL(location.href);
    url.hash = `#${path}`;

    this.location.replace(url);
    this.set('lastSetURL', path);
  }
});
