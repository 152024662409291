import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { observer, computed } from '@ember/object';
import { later, run } from '@ember/runloop';
import { htmlSafe } from '@ember/string';
import MediaEvents from 'ln-ember-toolkit/mixins/media-events';
import $ from 'jquery';

const ANIMATION_DELAY = 400;

function touchEventXY(event) {
  const touch = event.originalEvent.touches[0] || event.originalEvent.changedTouches[0];

  return {
    x: touch.pageX || 0,
    y: touch.pageY || 0
  };
}

export default Component.extend(MediaEvents, {

  state: service(),

  settings: service(),

  communicator: service(),

  clientTheme: service(),

  classNames: [
    'communicator-base-container-component'
  ],

  classNameBindings: [
    'communicator.isVisible',
    'state.communicatorIsOpen:is-open:is-closed',
    'state.navigationIsOpen',
    'showTabs',
    'sliding',
    'clientTheme.className'
  ],

  detailsFor: null,

  detailsOpen: false,

  tabsAreOpen: false,

  touchStartPoint: null,

  touchMovePoint: null,

  onTabVisibilityChange: () => {},

  tabsConf: alias('communicator.tabsConf'),

  currentTab: alias('communicator.currentTab'),

  badge: alias('communicator.badgeNumber'),

  slideStyle: computed('touchStartPoint.x', 'touchMovePoint.x', function() {
    const startPoint = this.touchStartPoint;
    const movePoint = this.touchMovePoint;

    if (!startPoint || !movePoint) {
      return null;
    }

    const windowWidth = $(window).width();
    const diff = startPoint.x - movePoint.x;

    if (diff >= 0) { return null; }

    let translateX = Math.abs(diff) / 2;

    if (this.get('mediaMatcher.isMobile')) {
      translateX = Math.abs(diff) - windowWidth;
    }

    return htmlSafe(`transform: translate3d(${translateX}px, 0, 0);`);
  }),

  showTabs: computed(
    'tabsAreOpen',
    'state.communicatorIsOpen',
    'settingValues.AlwaysShowCommunicator',
    'isDesktop',
    function() {
      return this.tabsAreOpen
      || this.get('state.communicatorIsOpen')
      || (this.isDesktop && this.get('settingValues.AlwaysShowCommunicator'));
    }
  ),

  tabs: computed('tabsConf.{[],@each.hasAccess}', function() {
    return this.tabsConf
      .filter((tab) => tab.get('hasAccess'));
  }),

  showTabsObserver: observer('showTabs', function() {
    this.onTabVisibilityChange(this.showTabs);
  }),

  didInsertElement() {
    this._super(...arguments);
    this.state
      .on('hide-click-catcher', this, this.close);

    this.settings
      .on('settings-did-change', this, this.updateSettingValues);

    this.updateSettingValues();
  },

  actions: {
    close() {
      if (this.detailsOpen) {
        this.set('detailsOpen', false);
      } else {
        this.close();
      }
    },

    toggleTab(tab) {
      if (tab.get('active') && this.get('state.communicatorIsOpen')) {
        this.close();
      } else {
        this.open(tab);
        this.state.showClickCatcher();
      }
    },

    toggleCommunicator(state = !this.tabsAreOpen) {

      if (this.get('communicator.currentTab')
        || (this.get('settingValues.AlwaysShowCommunicator') && (this.showTabs && !this.get('state.communicatorIsOpen')))) {
        return;
      }
      if (state) {
        this.openTabs();
      } else {
        this.closeTabs();
      }
    },

    forceOpenCommunicator() {
      this.toggleProperty('settingValues.AlwaysShowCommunicator');
    },

    logout() {
      this.state.triggerLogout();
    }
  },
  willRemoveElement() {
    this.state
      .off('hide-click-catcher', this, this.close);

    this.settings
      .off('settings-did-change', this, this.updateSettingValues);
  },

  updateSettingValues() {
    this.settings
      .getValues(['AlwaysShowCommunicator'])
      .then((values) => this.set('settingValues', values));
  },

  open(tab = null) {
    if (!tab && !this.currentTab) {
      tab = this.set('tabs.0');
    }

    if (tab) {
      const delay = this.get('state.communicatorIsOpen') ? 0 : ANIMATION_DELAY;
      this.communicator.setCurrentTab(tab, delay);
    }

    this.state.setCommunicatorIsOpen(true);
  },

  close() {
    this.state.setCommunicatorIsOpen(false);

    const { currentTab } = this;
    if (currentTab) {
      run.later(() => currentTab.set('active', false), ANIMATION_DELAY);
    }

    this.closeTabs();
  },

  openTabs() {
    if (!this.tabsAreOpen && !this.isDestroyed) {
      this.set('tabsAreOpen', true);
      this.state.showClickCatcher();
    }
  },

  closeTabs() {
    if (this.tabsAreOpen && !this.isDestroyed) {
      this.set('tabsAreOpen', false);
      this.state.hideClickCatcher();
    }
  },

  touchStart(event) {
    this.set('touchStartPoint', touchEventXY(event));
  },

  touchMove(event) {
    this.set('touchMovePoint', touchEventXY(event));
    this.set('sliding', true);
  },

  touchEnd(event) {
    const startPoint = this.touchStartPoint;
    const endPoint   = touchEventXY(event);

    const diff = startPoint.x - endPoint.x;

    if (diff >= 0) { return; }

    const windowWidth = $(window).width();
    const windowPortion = this.get('mediaMatcher.isMobile') ? 4 : 7;

    this.set('touchStartPoint', null);
    this.set('touchMovePoint', null);

    if (Math.abs(diff) >= windowWidth / windowPortion) {
      this.close();
    }
    later(this, () => this.set('sliding', false), ANIMATION_DELAY);
  }

});
