import Component from '@ember/component';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({

  microMenu: service(),

  classNames: [
    'communicator-notification-item-component',
    'list-item'
  ],

  classNameBindings: [
    'notification.context_type:has-action',
    'notification.read_state:is-read',
    'notification.flagged:is-flagged',
    'notification.archived:is-archived',
    'notification.sender_id:is-user'
  ],

  notification: null,

  option: () => {},

  filterIsArchive: equal('filter', 'archive'),

  actions: {
    option(...args) {
      this.option(...args);
    },

    openMenu(component, e) {
      this.microMenu.open(e.target.id, component);

      return false;
    }
  },
  click() {
    this.triggerAction({
      action: 'openNotification',
      actionContext: this.notification
    });
  }

});
