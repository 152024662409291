import { observer, computed } from '@ember/object';
import { or, alias } from '@ember/object/computed';
import ItemBase from './item-base';
import MediaEvents from 'ln-ember-toolkit/mixins/media-events';

export default ItemBase.extend(MediaEvents, {

  classNames: [
    'contact-list-item-contact-component',
    'mediaMatcher.isMobile'
  ],

  classNameBindings: [
    'hasOpenPopup',
    'isScrolling'
  ],

  hasOpenPopup: false,

  onPopupChange: () => {},

  contact: alias('item.contact'),

  hasPhoneInformation: or('contact.phone', 'contact.fax', 'contact.mobile_phone'),

  nameInitials: computed('contact', function() {
    const user = this.contact;
    const initials = user.first_name.charAt(0).concat(user.name.charAt(0));

    return initials;
  }),

  sendPopupChange: observer('hasOpenPopup', function() {
    this.onPopupChange(this.hasOpenPopup);
  }),

  actions: {
    toggleDetails() {
      this.toggleProperty('hasOpenPopup');
    },

    closePopup() {
      this.set('hasOpenPopup', false);
    }
  },
  click() {
    if (this.isMobile) {
      this.set('hasOpenPopup', true);
    }
  },

  mouseLeave() {
    this.set('hasOpenPopup', false);
  }

});
