import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  microMenu: service(),

  classNames: [
    'contact-list-item-base-component'
  ],

  actions: {
    openMenu(component, e) {
      this.microMenu.open(e.target.id, component);

      return false;
    }
  }
});
