import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import injectConfig from '../utils/inject-config';
import io from 'socket.io-client';

export default Service.extend(Evented, {

  config: injectConfig(),

  state: service(),

  subscribe() {

    if (this.socket) {
      this.unsubscribe();
    }

    const transports = this.get('config.ln-pub-sub-service.transports') || [
      'websocket',
      'polling'
    ];
    const serviceUrl = this.get('config.ln-pub-sub-service.url');
    if (!serviceUrl) {
      console.error('[PubSub] Configuration for `ln-pub-sub-service.url` missing!');
      return;
    }

    const token = this.get('state.session.token');

    if (!token) {
      console.error('[PubSub] Session missing!');
      return;
    }

    const { url, path } = this.splitUrl(serviceUrl);

    this.socket = io
      .connect(url, { path, forceNew: true, transports })
      .emit('authentication', { token })
      .on('reconnect', () => this.socket.emit('authentication', { token }))
      .on('event', (event) => {
        this.trigger('event', event);
        if (this.get('config.log.pubSub.events')) {
          // eslint-disable-next-line no-console
          console.log('[PubSub] event', JSON.stringify(event));
        }
      })
      .on('error', (error) => console.error('[PubSub]', error));
  },

  unsubscribe() {
    if (!this.socket) {
      return;
    }

    this.socket.io.disconnect();
    this.socket = null;
  },

  splitUrl(serviceUrl) {
    const url = new URL(serviceUrl);
    const path = (url.pathname === '/')
      ? '/socket.io'
      : `${url.pathname.replace(/\/$/, '')}/socket.io`;

    url.pathname = '';

    return { url: url.toString(), path };
  }
});
