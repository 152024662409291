import Evented from '@ember/object/evented';
import RSVP from 'rsvp';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';
import Service, { inject as service } from '@ember/service';
import DS from 'ember-data';

const {
  PromiseArray
} = DS;

const defaultValues = {
  NotificationSound: false,
  AlwaysShowCommunicator: false,
  UserGuideSeen: 0
};
/**
 * @class SettingsService
 */
export default Service.extend(Evented, {

  api: service(),

  state: service(),

  flashMessages: service(),

  shouldReload: null,

  settings: computed('shouldReload', 'state.session.user_id', function() {
    const userId = this.get('state.session.user_id');
    const promise = this.api
      .read('myliga', ['users', userId, 'settings'])
      .then((resp) => resp.result)
      .catch((error) => {
        this.flashMessages.addMessage('error', 'Settings not loaded.');
        console.error(error);
      });

    return PromiseArray.create({ promise });
  }),

  /**
   *
   * @memberof SettingsService
   * @instance
   *
   * @method getValue
   * @param {String} name
   * @returns {any}
   */
  getValue(name) {
    return this.settings.then((settings) => {
      const { value } = (settings || []).findBy('name', name) || {};

      if (isNone(value)) {
        return defaultValues[name];
      }

      return value;
    });
  },

  /**
   * @memberof SettingsService
   * @instance
   *
   * @method getValue
   * @param {String[]} names
   * @returns {array}
   */
  getValues(names) {
    return RSVP.hash(names.reduce((all, name) => {
      all[name] = this.getValue(name);
      return all;
    }, {}));
  },

  /**
   * @memberof SettingsService
   * @instance
   *
   * @method setValue
   * @param {String} name
   * @param {any} value
   * @return {Promise<Object>} setting
   */
  setValue(name, value) {
    return this.settings.then((settings) => {
      const setting = (settings || []).findBy('name', name) || this.createSetting(name);

      if (setting.value !== value) {
        setting.value = value;
        setting._isDirty = true;
      }
      return setting;
    });
  },

  createSetting(name, value = null) {
    const setting = {
      id: null,
      version: null,
      name,
      value,
      _isDirty: true
    };

    this.settings.pushObject(setting);

    return setting;
  },

  /**
   * @memberof SettingsService
   * @instance
   *
   * @method saveAllSettings
   * @param {String} name
   * @return {Promise<Object[]>} settings
   */
  saveAllSettings() {

    let hasChangedSettings = false;

    return this.settings.then((settings) => {
      const changedSettings = (settings || []).filterBy('_isDirty');
      hasChangedSettings = changedSettings.length > 0;
      const promises = changedSettings.map((setting) => this.saveSetting(setting));

      return RSVP.all(promises);
    }).then(() => {
      if (hasChangedSettings) {
        this.notifyPropertyChange('settings');
        this.trigger('settings-did-change');
      }

      return this.settings;
    });
  },

  /**
   * @memberof SettingsService
   * @instance
   *
   * @method saveSetting
   * @param {Object} setting
   * @return {Promise<Object>} setting
   */
  saveSetting(setting) {
    const userId = this.get('state.session.user_id');

    delete setting._isDirty;
    if (setting.id) {
      return this.api.update('myliga', ['users', userId, 'settings', setting.id], setting);
    }

    return this.api.create('myliga', ['users', userId, 'settings'], setting);
  },

  /**
   * @memberof SettingsService
   * @instance
   *
   * @method reloadAllSettings
   * @return {Promise<Object[]>} settings
   */
  reloadAllSettings() {
    this.notifyPropertyChange('shouldReload');
    return this.settings;
  }
});
