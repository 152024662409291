import { set, get } from '@ember/object';

export function propertyHasChanged(obj, key, value) {
  return JSON.stringify(get(obj, key)) !== JSON.stringify(value);
}

export default function setIfChanged(obj, key, value) {
  if (propertyHasChanged(obj, key, value) && !obj.isDestroyed) {
    set(obj, key, value);
    return true;
  }

  return false;
}
