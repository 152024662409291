import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';

export default Controller.extend({

  token: alias('model.token'),

  actions: {
    goToLogin() {
      this.transitionToRoute('login');
    }
  }

});
