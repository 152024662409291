import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import {
  decodeHashPart,
  encodeHashPart,
  replaceLocationHash
} from 'ln-liga-os/utils/url';
import { scheduleOnce } from '@ember/runloop';
import injectConfig from '../utils/inject-config';
import formatErrorMessage from 'ln-liga-os/utils/format-error-message';

export default Route.extend({

  microMenu: service(),

  api: service(),

  state: service(),

  clientTheme: service(),

  flashMessages: service(),

  navigation: service(),

  pubSub: service(),

  communicator: service(),

  guideTooltips: service(),

  settings: service(),

  previousUrl: null,

  previousUserId: null,

  config: injectConfig(),

  beforeModel(transition) {
    return this.api.read('myliga', '/sessions')
      .then(() => this.settings.getValue('UserGuideSeen'))
      .then((value) => {
        if (value === 0) {
          this.set('guidesActive', true);
          this.setupNavigationGuides();
        }
      })
      .catch((error) => {
        if (error.status_code === 401) {
          const session = this.get('state.session');

          this.set('previousUrl', get(transition, 'to.params.url'));
          this.state.setSession(null);

          this.onDidLogout(session);
        }
      });
  },

  model({ url }) {
    const previousUrl = this.popPreviousUrl();
    if (previousUrl) {
      url = previousUrl;
      // '!' means no app selected
    } else if (!url || url === '!') {
      url = this.getLastVisitedUrl() || '!';
    }

    try {
      // Replace encoded pipes.
      replaceLocationHash(/%7C/g, '|');
    } catch(error) {
      // mute
    }

    return this.state.loadUser()
      .then(() => this.navigation.loadNavigations())
      .then(() => {
        const appUrl = this.decodeRoute(url);

        const currentRouteUrl = this.encodeRoute(this.get('navigation.currentUrl'));
        if (currentRouteUrl && currentRouteUrl !== '!' && currentRouteUrl !== url) {
          this.navigation.openUrl(appUrl);
        }

        if (appUrl) {
          if (!this.navigation.setUrl(appUrl)) {
            return;
          }

          // upon the initial login into the app, the `activate` hook wasn't called yet
          this.onUrlDidChange(appUrl);
        } else {
          this.navigation.openFirstItem();
        }

        this.navigation.loadedAppCheck();
        this.state.setAppTitle(this.get('navigation.currentItem.name'));
      })
      .catch((error) => {
        const errorMessage = formatErrorMessage(error, null);
        this.flashMessages.addMessage('error', errorMessage);
        console.error(error);
      });
  },

  afterModel() {
    this.checkRedirect();
  },

  activate() {
    this.pubSub.subscribe();
    this.communicator.subscribe();
    this.navigation.on('url-did-change', this, this.onUrlDidChange);
    this.state.on('did-logout', this, this.onDidLogout);
    this.state.on('language-did-change', this, this.onLanguageDidChange);
  },

  setupController() {
    this._super(...arguments);

    this.microMenu.enable();
  },

  deactivate() {
    this.pubSub.unsubscribe();
    this.communicator.unsubscribe();
    this.navigation.off('url-did-change', this, this.onUrlDidChange);
    this.state.off('did-logout', this, this.onDidLogout);
    this.state.off('language-did-change', this, this.onLanguageDidChange);

    document.body.classList.remove('supports-micro-menu');
  },

  checkRedirect() {
    if (this.get('config.theme') === 'parasol' && this.get('config.api-parasol')) {
      return this.api.read('parasol', 'api/session')
        .then((response) => {
          if (response.queue_next_course && response.next_course && this.navigation.currentApp.slug !== 'parasol-academy') {
            this.navigation.openUrl(this.get('config.api-parasol.url'));
            // upon the initial login into the app, the `activate` hook wasn't called yet
            this.onUrlDidChange(this.get('config.api-parasol.url'));
          }
        });
    }
  },

  setupNavigationGuides() {
    const guides = this.navigationGuides;

    scheduleOnce('afterRender', () => {
      this.guideTooltips.setGuides(guides, 'navigation-guides');
      this.guideTooltips.on('guides-finished', this, this.teardownNavigationGuides);
    });
  },

  teardownNavigationGuides(label) {
    if (label !== 'navigation-guides') {
      return;
    }

    return this.settings.setValue('UserGuideSeen', 1).then((setting) => {
      return this.settings.saveSetting(setting);
    });
  },

  encodeRoute(url) {
    url = encodeHashPart(url);
    url = this.navigation.addSlug(url);

    return url;
  },

  decodeRoute(url) {
    if (!url) {
      return null;
    }

    url = this.navigation.removeSlug(url);

    return decodeHashPart(url);
  },

  onDidLogout(session) {
    if (session) {
      this.set('previousUserId', get(session, 'user_id'));
    }
    this.transitionTo('login');
  },

  onUrlDidChange(url) {
    const encodedUrl = this.encodeRoute(url);

    this.replaceWith(`/${encodedUrl}`);
    this.saveLastVisitedUrl(encodedUrl);
  },

  getLastVisitedUrl() {
    return sessionStorage.getItem('last-app-url');
  },

  saveLastVisitedUrl(url) {
    sessionStorage.setItem('last-app-url', url);
  },

  onLanguageDidChange() {
    window.location.reload();
  },

  popPreviousUrl() {
    const url = this.previousUrl;
    const userId = this.previousUserId;

    this.setProperties({
      previousUrl: null,
      previousUserId: null
    });

    // If previous request was without a session or from the same user.
    if (url && (!userId || userId === this.get('state.session.user_id'))) {
      return url;
    }
  }
});
