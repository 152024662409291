import Component from '@ember/component';
import { computed } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import layout from 'ln-liga-os/templates/components/communicator/filter-base';

export default Component.extend({
  layout,

  classNames: [
    'communicator-filter-base-component'
  ],

  filters: null,

  hrStyle: computed('filterIdx', 'filters.length', function() {

    const x = this.filterIdx * 100;
    const width = 100 / this.get('filters.length');

    return `
      width: ${width}%;
      transform: translateX(${x}%);
    `.htmlSafe();
  }),

  filterIs: computed('filter', function() {
    return { [this.filter || '']: true };
  }),

  filterIdx: computed('filters.[]', 'filter', function() {
    const key = this.filter;
    return (this.filters || []).findIndex((filter) => filter.key === key);
  }),

  didInsertElement() {
    this._super(...arguments);

    if (!this.filter && this.defaultFilter) {
      scheduleOnce('afterRender', () => {
        this.set('filter', this.defaultFilter);
      });
    }
  },

  actions: {
    filter(filterKey) {
      this.set('filter', filterKey);
    }
  }
});
