import ItemBase from './item-base';

export default ItemBase.extend({

  classNames: [
    'contact-list-item-add-list-component'
  ],

  click() {
    this.addMailingList();
  }
});
