import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { observer, computed } from '@ember/object';
import moment from 'moment';
import TabBaseComponent from './tab-base';
import MediaEvents from 'ln-ember-toolkit/mixins/media-events';

export default TabBaseComponent.extend(MediaEvents, {

  api: service(),

  classNames: [
    'communicator-tab-birthdays-component'
  ],

  filter: null,

  birthdays: null,

  isLoading: false,

  offset: 0,

  perPage: 50,

  lastSubhead: null,

  showBirthday: true,

  searchText: '',

  birthdaysLoaded: false,

  filterQuery: computed('filter', 'searchText', function() {

    const { filter } = this;

    const fromDate = moment();
    let tillDate = moment().add(14, 'days');

    if (filter === 'today') {
      tillDate = fromDate;
    } else if (filter === 'coming') {
      fromDate.add(1, 'days');
    }

    return {
      active: true,
      search_text: this.searchText,
      birth_date_from: fromDate.format('MM-DD'),
      birth_date_till: tillDate.format('MM-DD'),
      sort: 'birth_date'
    };
  }),

  updateBirthdays: observer('filterQuery', function() {
    this.setProperties({
      birthdays: [],
      birthdaysLoaded: false,
      lastSubhead: null
    });

    this.send('loadMore');
  }),

  init() {
    this._super(...arguments);

    this.updateBirthdays();
  },

  actions: {
    loadMore() {
      if (this.isLoading || this.birthdaysLoaded) {
        return;
      }

      this.set('isLoading', true);

      const query = this.filterQuery;

      this.api.read('myliga', ['liga3000_contacts', query]).then((response) => {
        if (this.isDestroyed) { return; }

        const birthdays = this.parseBirthdays(response.result);
        this.birthdays.pushObjects(birthdays);
        this.set('isLoading', false);
        this.set('birthdaysLoaded', true);

        run.scheduleOnce('afterRender', () => {
          if (this.get('birthdays.length') <= 0) {
            this.birthdays.pushObject({
              type: 'no_results'
            });
          }
        });
      });
    },

    searchInputChanged(value) {
      this.set('searchText', value);
      this.updateBirthdays();
    }
  },
  parseBirthdays(birthdays) {
    const parsedBirthdays = [];
    const today = moment().format('DD-MM');

    birthdays.forEach((birthday) => {
      let subhead = 'Upcoming'.loc();
      let hasBirthday = false;
      const birthdayFormated = moment(birthday.birth_date).format('DD-MM');

      if (birthdayFormated === today) {
        hasBirthday = true;
        subhead = 'Today'.loc();
      }

      if (subhead !== this.lastSubhead) {
        parsedBirthdays.pushObject({
          type: 'subhead',
          text: subhead
        });

        this.set('lastSubhead', subhead);
      }

      parsedBirthdays.pushObject({
        detailsOpen: false,
        type: 'contact',
        contact: birthday,
        birthday: birthdayFormated,
        hasBirthday
      });
    });

    return parsedBirthdays;
  }

});
