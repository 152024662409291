import { getOwner } from '@ember/application';

export default function createObject(container, name, properties = {}) {

  const owner = getOwner(container);
  const Object = owner.resolveRegistration(`object:${name}`);

  return Object.create(
    owner.ownerInjection(),
    properties
  );
}
