import Helper from '@ember/component/helper';
import { get } from '@ember/object';
import { getOwner } from '@ember/application';

const missing = [];

export default Helper.extend({
  compute(params /* , hash */) {
    const [tab] = params;
    const identifier = get(tab, 'identifier');
    const key = `communicator/tab-${identifier.dasherize()}`;

    if (this.componentExists(key)) {
      return key;
    }

    if (missing.indexOf(key) === -1) {
      missing.push(key);
      console.error(`Missing commincator tab component: ${key}`);
    }

    return null;
  },

  componentExists(key) {
    return getOwner(this).hasRegistration(`component:${key}`);
  }
});
