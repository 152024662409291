import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import Ember from 'ember';
import injectConfig from '../utils/inject-config';

export default Route.extend({

  state: service(),

  api: service(),

  apiEvents: service(),

  navigation: service(),

  ligaOsApi: service(),

  clientTheme: service(),

  init() {
    this._super(...arguments);

    this.state
      .on('app-loaded', (loaded) => {
        if (loaded) {
          document.querySelector('body').classList.add('loaded');
          document.querySelector('body').classList.add('app-loaded');
        } else {
          document.querySelector('body').classList.remove('app-loaded');
        }
      });

    this.apiEvents.on('error-response', (response) => {
      this.onApiErrorResponse(response);
    });

    this.apiEvents.triggerConfigChange(this.config);

    this.ligaOsApi.register();

    if (this.get('config.exposeLigaOSApi')) {
      window.LIGAOS_API = this.ligaOsApi;
    }

    if (this.get('config.exposeApi')) {
      window.API = this.api;
    }
  },
  config: injectConfig(),

  beforeModel() {
    return this.clientTheme.load().then(() => this.state.restore());
  },

  model() {
    const config = getOwner(this).resolveRegistration('config:environment');

    const langCode = this.ligaOsApi.getLanguage();
    Ember.STRINGS = config.STRINGS[langCode] || {};
  },

  onApiErrorResponse(response) {
    if (response.status === 401) {
      this.state.triggerLogout();
    }
  }

});
