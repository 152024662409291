import { isNone } from '@ember/utils';
import { not } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import { getOwner } from '@ember/application';
import { observer, computed } from '@ember/object';
import * as Sentry from '@sentry/browser';
import RSVP from 'rsvp';
import Service, { inject as service } from '@ember/service';
import Ember from 'ember';
import DS from 'ember-data';
import setIfChanged from '../utils/set-if-changed';
import injectConfig from 'ln-liga-os/utils/inject-config';
const { PromiseArray } = DS;

export default Service.extend(Evented, {

  apiEvents: service(),

  api: service(),

  storage: service(),

  config: injectConfig(),

  settings: service(),

  store: service(),

  rights: service(),

  communicator: service(),

  clientTheme: service(),

  appLoaded: false,

  appLocation: false,

  session: null,

  showClickCatcherMask: false,

  showStartupMask: true,

  showLoaderMask: not('appLoaded'),

  notificationsCount: computed('communicator.badges', function() {
    return (Number(this.get('communicator.badges.notifications')) || 0) + (Number(this.get('communicator.badges.tasks')) || 0);
  }),

  titleCount: computed('communicator.badgeNumber', 'appCount', 'notificationsCount', function() {
    if (this.appCount) {
      return this.appCount + (this.notificationsCount || 0);
    }

    return this.communicator.badgeNumber;
  }),

  languages: computed(function() {
    return PromiseArray.create({
      promise: this.store.query('myliga/language', { active: true, native_spelling: true })
    });
  }),

  init() {
    this._super(...arguments);
    this.on('did-logout', () => this.reset());
  },

  updateTitleCount: observer('titleCount', function() {
    this.updateTitle();
  }),

  updateShowStartupMask: observer('appLoaded', 'session', function() {
    if (!this.session) {
      this.set('showStartupMask', true);
    }

    if (this.showStartupMask && this.appLoaded) {
      this.set('showStartupMask', false);
    }
  }),

  reset() {
    this.set('user', null);
    this.set('appError', null);
    this.set('appLoaded', false);
    this.set('appTitle', null);
    this.set('appCount', 0);

    this.communicator.reset();

    this.rights.reset();
    this.updateTitle();
  },

  resetTitle() {
    this.set('appCount', 0);
    this.set('appTitle', null);
  },

  // Called in application route
  restore() {
    this.setSession(this.storage.get('ln-liga-os:session', null));
    this.apiEvents.triggerLanguageChange(this.getLanguage());
    this.updateStrings();
    this.updateTitle();
  },

  loadUser(force = false) {
    if (!force && Number(this.get('user.id')) === this.get('session.user_id')) {
      return RSVP.resolve();
    }

    if (isNone(this.get('session.user_id'))) {
      return RSVP.reject(new Error('session.user_id is not set'));
    }

    return this.store.findRecord('myliga/user', this.get('session.user_id'))
      .then((user) => this.set('user', user))
      .then(() => this.languages)
      .then((languages) => {
        const language = languages.findBy('id', String(this.get('user.language_id')));
        if (language) {
          this.setLanguage(language.get('code'), false);
        }
      });
  },

  setSession(session) {
    if (setIfChanged(this, 'session', session)) {
      this.storage.set('ln-liga-os:session', session);
      this.apiEvents.triggerSessionChange(session);

      // Set Sentry user context
      if (session) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: `${session.user_id}`
          });
        });
      }
    }
  },

  setLanguage(code, reload = true) {
    if (code !== this.getLanguage()) {
      this.storage.set('ln-liga-os:language', code);
      this.apiEvents.triggerLanguageChange(code);
      this.updateStrings();

      if (reload) {
        this.trigger('language-did-change', code);
      }
    }
  },

  getLanguage() {
    return this.storage.get('ln-liga-os:language') || this.detectLanguage();
  },

  setAppLoaded(appLoaded) {
    if (setIfChanged(this, 'appLoaded', appLoaded)) {
      this.trigger('app-loaded', appLoaded);
    }
  },

  setAppTitle(appTitle = null) {
    // TODO remove appTitle on app switch!
    if (setIfChanged(this, 'appTitle', appTitle)) {
      this.updateTitle();
    }
  },

  setTitleCount(count = 0) {
    // TODO remove prefix on app switch!
    if (setIfChanged(this, 'appCount', count)) {
      this.updateTitle();
    }
  },

  updateTitle() {
    let title = this.get('clientTheme.data.title') || 'LIGAOS';

    if (this.appTitle) {
      title = `${this.appTitle} - ${title}`;
    }

    if (this.titleCount) {
      title = `(${this.titleCount}) ${title}`;
    }

    document.querySelector('title').innerHTML = title;
    document.title = title;
  },

  showClickCatcher() {
    this.set('showClickCatcherMask', true);
    this.trigger('show-click-catcher');
  },

  hideClickCatcher() {
    if (this.showClickCatcherMask) {
      this.set('showClickCatcherMask', false);
      this.trigger('hide-click-catcher');
    }
  },

  toogleNavigationIsOpen() {
    this.setNavigationIsOpen(!this.navigationIsOpen);
  },

  setNavigationIsOpen(open) {
    if (setIfChanged(this, 'navigationIsOpen', open)) {
      if (open) {
        this.showClickCatcher();
      } else {
        this.hideClickCatcher();
      }
    }
    // TODO trigger event?
  },

  toogleCommunicatorIsOpen() {
    this.setCommunicatorIsOpen(!this.communicatorIsOpen);
  },

  setCommunicatorIsOpen(open) {
    if (setIfChanged(this, 'communicatorIsOpen', open)) {
      if (open) {
        this.showClickCatcher();
      } else {
        this.hideClickCatcher();
      }
    }
    // TODO trigger event?
  },

  propertyHasChanged(key, value) {
    return JSON.stringify(this.get(key)) !== JSON.stringify(value);
  },

  triggerNotificationSound() {
    this.settings
      .getValue('NotificationSound')
      .then((enabled) => {
        if (enabled) {
          this.trigger('notification-sound');
        }
      });
  },

  triggerLogout() {
    const { session } = this;
    if (!session) {
      return;
    }

    this.validateSession().then((valid) => {
      if (valid) {
        this.api.delete('myliga', 'sessions');
      }

      this.setSession(null);
      this.trigger('did-logout', session);
    });
  },

  triggerAppErrorReset() {
    if (this.appError) {
      this.set('appError', null);
      this.trigger('app-error-reset');
    }
  },

  triggerAppError(error) {
    this.set('appError', error);
    this.trigger('app-error', error);
    this.setAppLoaded(true);
  },

  validateSession() {
    return new RSVP.Promise((resolve) => {

      if (!this.session) {
        return resolve(false);
      }

      this.api
        .read('myliga', 'sessions')
        .then(() => resolve(true))
        .catch(() => resolve(false));
    });
  },

  detectLanguage() {
    const { languages = [] } = window.navigator || {};
    const { STRINGS } = getOwner(this).resolveRegistration('config:environment');
    return languages.find((code) => Object.keys(STRINGS).includes(code));
  },

  updateStrings() {
    const { STRINGS } = getOwner(this).resolveRegistration('config:environment');
    Ember.STRINGS = STRINGS[this.getLanguage()] || {};
  }
});
