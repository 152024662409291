import './pollyfills/string-repeat';
import './pollyfills/object-assign';
import './pollyfills/url';

import Application from '@ember/application';
import { InitSentryForEmber as initSentry } from '@sentry/ember';
import loadInitializers from 'ember-load-initializers';
import Resolver from './resolver';
import config from './config/environment';

if (!['development', 'test'].includes(config.environment)) {
  initSentry({
    release: `${config.APP.name}@${config.APP.version}`
  });
}

const App = Application.extend({
  modulePrefix: config.modulePrefix,
  podModulePrefix: config.podModulePrefix,
  Resolver
});

loadInitializers(App, config.modulePrefix);

export default App;
