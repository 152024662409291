import { helper } from '@ember/component/helper';
import Grid from 'ember-collection/layouts/grid';
import { formatPercentageStyle } from 'ember-collection/utils/style-generators';

class FullWidthGrid extends Grid {

  constructor(cellHeight) {
    super(10000, cellHeight);
  }

  formatItemStyle(itemIndex, clientWidth, clientHeight) {
    const pos = this.positionAt(itemIndex, clientWidth, clientHeight);
    const height = this.heightAt(itemIndex, clientWidth, clientHeight);
    return formatPercentageStyle(pos, 100, height);
  }

}

export default helper(function([cellHeight]) {
  return new FullWidthGrid(cellHeight);
});
