import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({

  state: service(),

  tagName: 'audio',

  attributeBindings: ['src'],

  src: null,

  didInsertElement() {
    this._super(...arguments);

    this.state.on('notification-sound', this, this.play);
  },

  willRemoveElemnt() {
    this._super(...arguments);

    this.state.off('notification-sound', this, this.play);
  },

  // TODO: Needs to be triggered somehow when the PubSub Service is done
  play() {

    if (this.isDestroyed) { return; }

    const player = this.element;
    player.currentTime = 0;
    player.play();
  }
});
