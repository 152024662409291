import FilterBase from './filter-base';
import { inject as service } from '@ember/service';
import { loc } from '@ember/string';

export default FilterBase.extend({
  rights: service(),

  classNames: [
    'communicator-filter-contacts-component'
  ],

  defaultFilter: 'all',

  filters: null,

  init() {
    this.set('filters', [
      { key: 'all',      label: loc('All') },
      { key: 'projects', label: loc('Projects') }
    ]);

    this.rights.can('READ', 'EmailDistributor').then((canRead) => {
      if (canRead) {
        this.filters.addObject({ key: 'lists', label: loc('Lists') });
      }
    });

    this._super(...arguments);
  }
});
