import Controller from '@ember/controller';
import { isEmpty } from '@ember/utils';
import { computed, get } from '@ember/object';
import { and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import injectConfig from '../utils/inject-config';

export default Controller.extend({

  api: service(),

  state: service(),

  clientTheme: service(),

  storage: service(),

  flashMessages: service(),

  username: null,

  password: null,

  message: null,

  invalid: false,

  btnInvalid: false,

  showPasswordInput: false,

  showPasswordReset: false,

  showPasswordResetRequested: false,

  config: injectConfig(),

  passwordInvalid: and('showPasswordReset', 'invalid', 'btnInvalid'),

  loginInvalid: and('showLogin', 'invalid', 'btnInvalid'),

  passwordInputType: computed('showPasswordInput', function() {
    return this.showPasswordInput ? 'text' : 'password';
  }),

  showLogin: computed('showPasswordReset', 'showPasswordResetRequested', function() {
    return !this.showPasswordReset && !this.showPasswordResetRequested;
  }),

  loggedIn: computed('state.session', function() {
    return !isEmpty(this.get('state.session'));
  }),

  actions: {
    resetErrorState() {
      this.set('btnInvalid', false);
    },

    login() {
      this.resetMessage();

      this.api
        .create('myliga', '/sessions', {
          password: this.password || '',
          user_name: this.username || '',
          app_key: this.get('config.api-myliga.app_key')
        })
        .then((session) => {
          this.resetMessage();

          this.state.setSession(session);
          this.transitionToRoute('/!');
        })
        .catch((err) => {
          const msg = get(err, 'message') || 'Login failed!';
          this.setError(msg);
        });

      return false;
    },

    togglePasswordReset() {
      this.resetMessage();
      this.set('password', null);
      this.toggleProperty('showPasswordReset');
    },

    goToLogin() {
      this.setProperties({
        username: '',
        showPasswordReset: false,
        showPasswordResetRequested: false
      });
    },

    passwordReset() {
      this.resetMessage();
      this.api
        .create('myliga', ['security', 'password_resets'], { email_or_username: this.username })
        .then(() => {
          this.set('showPasswordReset', false);
          this.set('showPasswordResetRequested', true);
        })
        .catch((error) => {
          this.flashMessages.addErrorMessages(error);
        });
    },

    togglePasswordIcon() {
      this.toggleProperty('showPasswordInput');
    }
  },
  setError(msg) {
    this.set('invalid', true);
    this.set('btnInvalid', true);
    this.set('message', msg);
  },

  resetMessage() {
    this.set('invalid', false);
    this.set('btnInvalid', false);
    this.set('message', null);
  }

});
