import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { computed } from '@ember/object';
import injectConfig from '../utils/inject-config';
import { PREFIX_QUERY_PARAM_NAME } from 'ln-ember-toolkit/utils/liga-os';

export default Component.extend({

  navigation: service(),

  state: service(),

  classNames: [
    'app-content-component'
  ],

  classNameBindings: [
    'loaded',
    'state.communicatorIsOpen',
    'showBackground'
  ],

  attributeBindings: [
    'style'
  ],

  url: null,

  config: injectConfig(),

  style: computed('showBackground', 'navigation.currentApp.background', function() {
    let bg = 'transparent';

    if (this.showBackground) {
      bg = this.get('navigation.currentApp.background');
    }

    return `background-color: ${bg}`.htmlSafe();
  }),

  showBackground: computed('state.{appLoaded,appError}', 'navigation.currentApp.background', function() {
    return !!(this.state.appLoaded && !this.state.appError && this.navigation.currentApp.background);
  }),

  didInsertElement() {
    this._super(...arguments);
    this.navigation.on('open-url', this, this.updateFrameUrl);
    this.updateFrameUrl();
  },

  willRemoveElement() {
    this._super(...arguments);
    this.navigation.off('open-url', this, this.updateFrameUrl);
  },

  updateFrameUrl() {

    const { currentUrl } = this.navigation;
    if (!currentUrl) {
      return;
    }

    scheduleOnce('afterRender', () => {
      if (!this.element || this.isDestroyed) {
        return;
      }

      const { origin } = location;
      const fullUrl = currentUrl.match(/^\/[^/]/) ? `${origin}${currentUrl}` : currentUrl;
      const iframeUrl = new URL(fullUrl);

      const app = this.navigation.getAppFor(currentUrl);
      if (app) {
        const ligaOsPrefix = `${origin}/#/!/${app.slug}`;
        iframeUrl.searchParams.append(PREFIX_QUERY_PARAM_NAME, ligaOsPrefix);
      }

      const appFrame = this.element.querySelector('.app-frame');
      appFrame && appFrame.setAttribute('src', iframeUrl.toString());
    });
  }
});
