import $ from 'jquery';
import Component from '@ember/component';
import { schedule } from '@ember/runloop';

export default Component.extend({

  classNames: [
    'scroll-button-component'
  ],

  classNameBindings: [
    'isVisible',
    'theme'
  ],

  scrollingElement: null,

  isVisible: false,

  didInsertElement() {
    this._super(...arguments);

    this.setScrollButton();

    $(this.scrollingElement).on('scroll', () => this.setScrollButton());
    $(window).on('orientationchange', () => {
      schedule('afterRender', () => this.setScrollButton());
    });
  },

  willDestroyElement() {
    this._super(...arguments);
    $(this.scrollingElement).off('scroll', () => this.setScrollButton());
    $(window).off('orientationchange', () => {
      schedule('afterRender', () => this.setScrollButton());
    });
  },

  setScrollButton() {
    const view = document.querySelector(this.scrollingElement);

    if (!view) { return; }

    const isButtonVisible = window.innerHeight < view.scrollHeight
      && view.scrollTop + window.innerHeight < view.scrollHeight;

    this.set('isVisible', isButtonVisible);
  },

  click() {
    $(this.scrollingElement).animate({
      scrollTop: window.innerHeight
    });
  }
});
