import { inject as service } from '@ember/service';
import Component from '@ember/component';
import MediaEvents from 'ln-ember-toolkit/mixins/media-events';
import $ from 'jquery';

export default Component.extend(MediaEvents, {

  state: service(),

  classNames: [
    'click-catcher-component'
  ],

  willDestroyElement() {
    this._super(...arguments);
    if (this.isMobile) {
      const clone = $(this.element).clone()
        .attr('id', `${$(this.element).attr('id')}-clone`)
        .addClass('destroying');

      clone.get(0).addEventListener('animationend', () => clone.remove(), false);
      $(this.element).parent().append(clone);
    }
  },

  click() {
    this.state.hideClickCatcher();
  }
});
