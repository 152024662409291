import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';
import TabBaseComponent from './tab-base';

export default TabBaseComponent.extend({

  api: service(),

  classNames: [
    'communicator-tab-absences-component'
  ],

  // Controller content

  absences: null,

  formattedAbsences: null,

  filter: 'all',

  offset: 0,

  perPage: 50,

  searchText: '',

  requestedForOffset: null,

  resetState: observer('filter', function() {
    this.set('absences', []);
    this.set('offset', 0);
    this.set('requestedForOffset', null);

    this.send('loadMore');
  }),

  init() {
    this._super(...arguments);

    this.set('absences', []);
    this.set('formattedAbsences', []);
    this.send('loadMore');
  },

  actions: {

    loadMore() {
      if (this.isLoading || this.requestedForOffset === this.offset) {
        return;
      }

      this.set('isLoading', true);

      const query = {
        paging_limit: this.perPage,
        paging_offset: this.offset
      };
      if (this.filter !== 'all') {
        // Turn filter into an array because `/absences` can receive multiple type params.
        query.type = this.filter.split(',');
      }

      if (this.searchText) {
        query.search_text = this.searchText;
      }

      this.api.read('myliga', ['communicator/absences', query]).then((response) => {
        if (this.isDestroyed) { return; }

        this.absences.pushObjects(response.result);

        this.set('requestedForOffset', this.offset);
        this.incrementProperty('offset', response.meta.count);

        this.set('formattedAbsences', this.formatAbsences(this.absences));

        this.set('isLoading', false);
      });
    },

    searchInputChanged(value) {
      this.set('searchText', value);
      this.resetState();
    }
  },
  formatAbsences(absences) {
    let formattedAbsences = [
      {
        type: 'subhead',
        text: 'Today'.loc()
      },
      {
        type: 'subhead',
        text: 'Upcoming'.loc()
      }
    ];

    let todayAbsences = false;

    (absences || []).forEach((absence) => {
      const start = moment(absence.date_from).format('DD-MM');
      const end   = moment(absence.date_till).format('DD-MM');
      let text = start;

      if (start !== end) {
        text += ` ${'to'.loc()}  ${end}`;
      }

      if (absence.reason) {
        text += ` | ${absence.reason}`;
      }

      const formatedAbsence = {
        detailsOpen: false,
        type: 'absence',
        contact: absence,
        reason: text,
        isIll: absence.type === 'Sickness',
        isHoliday: absence.type === 'Holiday'
      };

      // Sorting absences into Today/Upcoming
      if (moment(absence.date_from).isBefore(moment().add(1, 'days'), 'day')) {
        const upcomingIndex = formattedAbsences.findIndex(({ text }) => text === 'Upcoming'.loc());
        formattedAbsences.splice(upcomingIndex, 0, formatedAbsence);
        todayAbsences = true;
      } else {
        formattedAbsences.push(formatedAbsence);
      }
    });

    // Removing trailing 'Upcoming' in case there are 0 upcoming absences.
    if (formattedAbsences[formattedAbsences.length - 1].type === 'subhead') {
      formattedAbsences.pop();
    }

    // Remove 'Today' in case there are 0 absences today.
    if (!todayAbsences) {
      formattedAbsences.shift();
    }

    // Removing default Today/Upcoming objects from array in case there are no absences at all.
    if (formattedAbsences.length === 1) {
      formattedAbsences = [];
    }

    return formattedAbsences;
  }

});
