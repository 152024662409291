import { alias } from '@ember/object/computed';
import ItemBase from './item-base';

export default ItemBase.extend({
  classNames: [
    'contact-list-item-client-component'
  ],

  client: alias('item.client'),

  click() {
    this.onClientClick(this.item);
  }
});
