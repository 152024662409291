import Component from '@ember/component';
import { debounce } from '@ember/runloop';
import $ from 'jquery';

export default Component.extend({

  tagName: 'div',

  hasValue: false,

  classNames: [
    'search-input-component'
  ],

  classNameBindings: [
    'hasValue'
  ],

  attributeBindings: [
    'placeholder',
    'type'
  ],

  change: () => {},

  valueChange: () => {},

  placeholder: 'Search'.loc(),

  actions: {
    clear() {
      $('input:first', this.element).val('').trigger('input').focus();
    }
  },
  input(event) {

    this.set('hasValue', Boolean(event.target.value));

    this.change(event);
    this.valueChange(event.target.value);

    debounce(this, this.callValueDidChange, event.target.value, 250);
  },

  callValueDidChange(value) {
    this.valueChange(value);
  }

});
