import { escapeExpStr } from 'ln-ember-toolkit/utils/regexp';
import { PREFIX_QUERY_PARAM_NAME } from 'ln-ember-toolkit/utils/liga-os';

export function replaceLocationHash(search, replace) {
  const urlObj = new URL(location.href);
  urlObj.hash = location.hash.replace(search, replace);
  history.replaceState(undefined, undefined, urlObj.toString());
}

export function cleanAppUrl(url) {
  // Escape special characters with slash "foo[bar]-{baz}" -> "foo\[bar\]\-\{baz\}"
  const host = escapeExpStr(location.host);
  const { port } = location;
  const hostExp = port ? `^https?://${host}(:${port})?([^:]|$)` : `^https?://${host}([^:]|$)`;
  const replace = port ? '$2' : '$1';

  const urlParsed = new URL(url, location.origin);
  urlParsed.searchParams.delete(PREFIX_QUERY_PARAM_NAME);
  url = urlParsed.toString();

  return url
    // Replace with relative url if origin matches.
    // "https://example.com/foo?bar=baz" -> "/foo?bar=baz" if location.origin is "https://example.com"
    .replace(new RegExp(hostExp), replace)
    // Remove api-session-token query param.
    .replace(/api-session-token=[a-z0-9-]+/, '')
    // Remove question-mark if it’s not followed by a query param (if api-session-token from above was the only query param).
    // "http://example.com/foo?" -> "http://example.com/foo" and "http://example.com/foo?#bar" -> "http://example.com/foo#bar"
    .replace(/\?(#|$)/, '$1');
}

export function cleanUrl(url) {
  return (url || '')
    // Replace multiple slashes with one except the case when it’s after semicolon.
    // "http://example.com/////foo////bar///" -> "http://example.com/foo.bar/"
    .replace(/(^|[^:])\/\/+/g, '$1/')
    // Replace urlencoded pipes with decoded pipes.
    .replace(/%7C/g, '|');
}

export function decodeHashPart(part) {
  const pathRegExp = /^!\/([a-z0-9-]+)([/#?])(.*)/i;
  const hostRegExp = /^!(\/\/.+)$/;

  if (part && part !== '!') {
    part = part
      .replace(/(^\s*|\s*)$/g, '')
      .replace(/^#/, '');

    if (part.match(hostRegExp)) {
      part = part.replace(hostRegExp, `${location.protocol}$1`);

    } else if (part.match(pathRegExp)) {
      part = part.replace(pathRegExp, '/$1$2$3');

    } else if (part !== '!') {
      console.error(`invalid url part "${part}"`);
      part = '!';
    }

    part = cleanUrl(part);

    part = part.replace(/\|\|/g, '#');
    part = part.replace(/\|/g, '?');

    return part;
  }

  return '';
}

export function encodeHashPart(url) {

  url = cleanUrl(url);

  const appRegExp = /^(\/[a-z0-9-]+\/?)(#\/)?(.*)/i;
  const hostRegExp = /^https?:(\/\/.+)$/;

  if (url) {
    if (url.match(hostRegExp)) {
      url = url.replace(hostRegExp, '!$1');
    } else {
      const matches = url.match(appRegExp);
      if (matches) {
        url = `!${matches[1]}${matches[3] || ''}`;
      } else {
        url = '';
      }
    }

    url = url.replace(/#/g, '||');
    url = url.replace(/\?/g, '|');

    return url;
  }

  return '';
}
