import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Service from 'ln-ember-contact-menu/services/micro-menu';

export default Service.extend({
  state: service(),

  session: alias('state.session'),

  horizontalAttachement: 'left'
});
