import Service from '@ember/service';
import { isNone } from '@ember/utils';

function useCookies() {
  if (typeof localStorage === 'object') {

    if (localStorage.getItem('storage-supported-test:', true)) {
      return false;
    }

    try {
      localStorage.setItem('storage-supported-test', true);
      return false;
    } catch(e) {
      // Mute error
    }
  }

  return true;
}

function setCookie(key, value) {
  const expires = new Date();
  expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));

  document.cookie = [
    `${key}=${value}`,
    `expires=${expires.toGMTString()}`,
    'path=/'
  ].join('; ');
}

function getCookie(key) {
  const pre = `${key}=`;
  const value = document.cookie.split(';')
    .map((c) => c.replace(/(^\s+|\s+$)/g, ''))
    .find((c) => c.indexOf(pre) === 0);

  if (typeof value === 'string') {
    return value.substring(pre.length, value.length);
  }

  return null;
}

export default Service.extend({
  get(key, defaultValue) {
    key = `storage:${key}`;

    let value = null;

    try {
      value = JSON.parse(useCookies() ? getCookie(key) : localStorage.getItem(key));
    } catch(ex) { /* console.error(ex) */ }

    return isNone(value) ? defaultValue : value;
  },

  set(key, value) {
    key = `storage:${key}`;
    try {
      value = JSON.stringify(value);
    } catch(ex) {
      value = null;
    }

    if (useCookies()) {
      return setCookie(key, value);
    }

    localStorage.setItem(key, value);
  }
});
