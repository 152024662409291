import CommunicatorTabItem from '../objects/communicator-tab-item';

export function initialize(application) {
  application.register('object:communicator-tab-item', CommunicatorTabItem);
}

export default {
  name: 'register-objects',
  initialize
};
