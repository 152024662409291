import FilterBase from './filter-base';

export default FilterBase.extend({

  classNames: [
    'communicator-filter-absences-component'
  ],

  defaultFilter: 'all',

  filters: null,

  init() {
    this.set('filters', [
      { key: 'all',                   label: 'All'.loc() },
      { key: 'Sickness,Holiday',      class: 'absence' },
      { key: 'Travel',                class: 'travel' }
    ]);

    this._super(...arguments);
  }
});
