import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import injectTheme from 'ln-ember-config-utils/utils/inject-theme';

export default Component.extend({

  navigation: service(),

  attributeBindings: [
    'name:ariaLabel'
  ],

  classNames: [
    'navigation-item-component'
  ],

  classNameBindings: [
    'isActive',
    'isHighlighted',
    'item.icon_url::has-no-icon'
  ],

  item: null,

  hovereditem: null,

  higlight: () => {},

  theme: injectTheme(),

  iconStyle: computed('theme.communicatorApplicationsSize', function() {
    return `
      background-image: url(${this.get('item.icon_url')});
    `.htmlSafe();
  }),

  name: computed('item.name', function() {
    return this.item.name;
  }),

  isHighlighted: computed('hoveredItem', 'item', function() {
    const { item, hoveredItem, isActive } = this;
    return (!hoveredItem && isActive) || (hoveredItem === item);
  }),

  isActive: computed('item.id', 'navigation.currentItem.id', function() {
    return this.get('item.id') === this.get('navigation.currentItem.id');
  }),

  mouseEnter() {
    this.higlight(true);
  },

  mouseLeave() {
    this.higlight(false);
  }
});
