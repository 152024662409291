import { alias } from '@ember/object/computed';
import { observer, computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import TabBaseComponent from './tab-base';
import normalizeErrorResponse from 'ln-liga-os/utils/normalize-error-response';
import injectTheme from 'ln-ember-config-utils/utils/inject-theme';

export default TabBaseComponent.extend({

  state: service(),

  api: service(),

  settings: service(),

  store: service(),

  classNames: [
    'communicator-tab-settings-component'
  ],

  classNameBindings: [
    'hasPasswordValues',
    'hasPasswordFocus'
  ],

  settingValues: null,

  password: null,

  errorMessage: null,

  errorDetails: null,

  alwaysShowCommunicatorOptions: null,

  notificationSoundOptions: null,

  hasPasswordFocus: false,

  theme: injectTheme(),

  currentUser: alias('state.user'),

  languageOptions: computed('state.languages.[]', function() {
    return (this.get('state.languages') || []).map((language) => ({
      label: get(language, 'name'),
      value: Number(get(language, 'id'))
    }));
  }),

  showErrorMessage: computed('errorMessage', 'errorDetails', function() {

    const details = this.errorDetails || {};
    const hasDetails = Object.keys(details).find((key) => details[key]);

    return Boolean(this.errorMessage || hasDetails);
  }),

  hasPasswordValues: computed('password.{old,new,new_rep}', function() {
    return Boolean(this.get('password.old') || this.get('password.new') || this.get('password.new_rep'));
  }),

  resetPasswordError: observer('password.old', 'password.new', 'password.new_rep', function(_, key) {
    key = key.replace(/\./g, '_');
    if (this.get(`errorDetails.${key}`)) {
      this.set(`errorDetails.${key}`, null);
      this.notifyPropertyChange('errorDetails');
    }
  }),

  updateNotificationSound: observer('settingValues.NotificationSound', function() {
    const value = this.get('settingValues.NotificationSound');
    this.settings.setValue('NotificationSound', value);
  }),

  updateAlwaysShowCommunicator: observer('settingValues.AlwaysShowCommunicator', function() {
    const value = this.get('settingValues.AlwaysShowCommunicator');
    this.settings.setValue('AlwaysShowCommunicator', value);
  }),

  init() {
    this._super(...arguments);

    this.set('password', {
      old: null,
      new: null,
      new_rep: null
    });

    this.set('alwaysShowCommunicatorOptions', [
      { label: 'On'.loc(), value: true },
      { label: 'Off'.loc(), value: false }
    ]);

    this.set('notificationSoundOptions', [
      { label: 'On'.loc(), value: true },
      { label: 'Off'.loc(), value: false }
    ]);

    this.updateSettingsValues();
  },

  actions: {
    savePassword() {
      this.savePassword();
    },

    hideErrorMessage() {
      this.set('errorMessage', null);
    },

    setLanguage(languageId) {
      this.set('currentUser.language_id', languageId);
      this.saveUser();
    },

    setAlwaysShowCommunicator(value) {
      this.set('settingValues.AlwaysShowCommunicator', value);
      this.saveSettings();
    },

    setNotificationSound(value) {
      this.set('settingValues.NotificationSound', value);
      this.saveSettings();
    },

    setPasswordFocus(focus) {
      next(() => this.set('hasPasswordFocus', focus));
    }
  },
  resetAllPasswordError() {
    this.set('errorDetails', {});
    this.set('errorMessage', null);
  },

  updateSettingsValues() {
    this.settings
      .getValues([
        'NotificationSound',
        'AlwaysShowCommunicator'
      ])
      .then((values) => this.set('settingValues', values));
  },

  showError(error) {
    if (typeof error === 'string') {
      this.set('errorMessage', error);

    } else {
      const { message, details } = normalizeErrorResponse(error);

      this.set('errorMessage', message);
      this.set('errorDetails', details);
    }
  },

  saveUser() {
    const user = this.currentUser;
    if (!user) { return; }

    this.set('isSaving', true);

    return user.save()
      .then(() => {
        if (this.isDestroyed) { return; }

        const lang = this.get('state.languages').findBy('id', String(user.get('language_id')));
        if (lang) {
          this.state.setLanguage(lang.get('code'));
        }
      })
      .catch((error) => {
        if (this.isDestroyed) { return; }
        this.showError(error);
      })
      .finally(() => {
        if (this.isDestroyed) { return; }
        this.set('isSaving', false);
      });
  },

  saveSettings() {
    return this.settings.saveAllSettings()
      .catch((error) => {
        if (this.isDestroyed) { return; }

        this.showError(error);
        this.settings.reloadAllSettings()
          .then(() => this.updateSettingsValues());
      });
  },

  savePassword() {

    this.resetAllPasswordError();

    if (this.get('password.new') !== this.get('password.new_rep')) {
      return this.set('errorDetails', {
        password_new_rep: 'The new password and the password repeat must match'
      });
    }

    const userId = this.get('currentUser.id');
    if (!userId) {
      return;
    }

    this.set('isSaving', true);

    return this.api
      .update('myliga', ['users', userId, 'password'], {
        password_new: this.get('password.new'),
        password_old: this.get('password.old')
      })
      .then(() => {
        if (this.isDestroyed) { return; }
        this.set('passwordSaved', true);
      })
      .catch((error) => {
        if (this.isDestroyed) { return; }

        this.showError(error);
      })
      .finally(() => {
        if (this.isDestroyed) { return; }
        this.set('isSaving', false);
      });
  }

});
