import { get } from '@ember/object';
import { loc } from '@ember/string';

export default function formatErrorMessage(error, model = null) {
  let { message: errorMessage = loc('Unknown error') } = error;

  if (model) {
    get(model, 'errors.content')
      .forEach(({ message }) => errorMessage += `\n${message}`);
  } else {
    (get(error, 'details') || [])
      .forEach(({ message }) => errorMessage += `\n${message}`);
  }
  return errorMessage;
}
