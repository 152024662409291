import { assign } from '@ember/polyfills';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';
import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';
import injectTheme from 'ln-ember-config-utils/utils/inject-theme';
import ENV from '../config/environment';
import injectConfig from '../utils/inject-config';

function assetURL(path) {
  const { protocol, host } = location;
  const { APP: { version: appVersion }, rootURL } = ENV;

  return path ? `${protocol}//${host}${rootURL}assets/${path}?v=${appVersion}` : null;
}

export default Service.extend({

  api: service(),

  theme: injectTheme(),

  config: injectConfig(),

  themeData: null,

  defaultData: null,

  support: computed('data.type', function() {
    const type = this.get('data.type').toLowerCase();
    return this.get(`config.support.${type}`) || this.get('config.support.default') || {};
  }),

  isDefault: computed('data.type', function() {
    return ['Dark', 'Light', 'AMG'].includes(this.get('data.type'));
  }),

  data: computed('themeData', 'defaultData', function() {

    const defaultData = assign({}, this.defaultData);
    if (!this.themeData) { return defaultData; }

    const themeData = Object.keys(this.themeData).reduce((themeData, key) => {
      const value = this.get(`themeData.${key}`);
      if (!isNone(value)) {
        themeData[key] = this.get(`themeData.${key}`);
      }
      return themeData;
    }, {});

    return assign({}, defaultData, themeData);
  }),

  className: computed('data.type', function() {
    return `theme-${this.get('data.type').toLowerCase()}`;
  }),

  style: computed('data', function() {

    let {
      background_image: { preview_url: bgURL },
      logo_image: { preview_url: logoURL }
    } = this.data || {};

    if (this.get('data.type') === 'LIGANOVA') {
      logoURL = assetURL('themes/liganova/images/logo-ligaos.svg');
    }

    if (this.get('data.type') === 'AMG') {
      return;
    }

    const styles = [];

    if (bgURL) {
      styles.push(`
        .background-container, .startup-mask-component {
          background-image: url('${bgURL}') !important;
        }
      `);
    }

    styles.push(`
      .logo {
        background-image: url('${logoURL}') !important;
      }
    `);

    return htmlSafe(styles.join('\n'));
  }),

  init() {
    this._super();

    this.set('defaultData', {
      title: this.get('config.clientTheme.defaultTitle') || 'LIGAOS',
      type: this.get('config.clientTheme.defaultType') || 'LIGANOVA',
      background_image: {
        preview_url: null
      },
      logo_image: {
        preview_url: assetURL(this.get('theme.themeLogoUrl'))
      }
    });
  },

  favicon: computed('data.type', function() {
    const name = this.get('data.type').toLowerCase();
    return ['Dark', 'Light'].includes(name) ? 'default' : name;
  }),

  load() {
    const { search, hostname } = location;
    const [, domain] = search.match(/domain=([^&]+)/) || [];

    return this.api
      .read('myliga', ['themes', { domain: domain || hostname, paging_limit: 1 }])
      .then(({ result: [theme] }) => theme)
      .catch((error) => console.error(`GET /themes: ${error.message || error.responseText || error}`))
      .then((theme) => this.set('themeData', theme))
      .then(() => this.setup());
  },

  setup() {
    const { favicon } = this;
    const faviconElement = document.querySelector('#favicon');
    faviconElement && faviconElement.setAttribute('href', assetURL(`images/favicons/${favicon}.ico`));
  }
});
