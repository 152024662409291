import { alias } from '@ember/object/computed';
import ItemBase from './item-base';

export default ItemBase.extend({

  classNames: [
    'contact-list-item-mailing-list-component'
  ],

  classNameBindings: [
    'canEditList'
  ],

  list: alias('item.list'),

  actions: {
    toggleMailingList() {
      this.onMailinglistEdit(this.item);
    }
  }
});
