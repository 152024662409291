import { computed } from '@ember/object';
import { isNone } from '@ember/utils';
import { later, scheduleOnce } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import Evented, { on } from '@ember/object/evented';
import createObject from '../utils/create-object';

export default Service.extend(Evented, {

  api: service(),

  state: service(),

  rights: service(),

  pollingIntervalTime: 60000,

  badges: null,

  isVisible: true,

  currentTab: computed('tabsConf.@each.active', function() {
    return this.tabsConf.findBy('active');
  }),

  tabsConf: computed('state.session.user_id', function() {

    if (isNone(this.get('state.session.user_id'))) {
      return [];
    }

    return [
      { identifier: 'applications', hasRight: true },
      { identifier: 'notifications' },
      { identifier: 'tasks' },
      { identifier: 'contacts' },
      { identifier: 'absences' },
      { identifier: 'birthdays' },
      { identifier: 'settings', hasTitle: true, hasRight: true }
    ].map((tab) => {
      tab.target = this;
      return createObject(this, 'communicator-tab-item', tab);
    });
  }),

  badgeNumber: computed('tabsConf.@each.{hasAccess,badge}', 'state.user', function() {
    if (!this.get('state.user')) {
      return;
    }

    const allowedTabs = this.get('tabsConf').filter((tab) => tab.get('hasAccess'));

    return allowedTabs.reduce((a, b) => a + (b.badge || 0), 0);
  }),

  init() {
    this._super(...arguments);

    this.reset();
  },

  setCurrentTab(tab, delay = 0) {
    this.tabsConf.forEach((iTab) => {
      iTab.set('rendered', false);
      iTab.set('active', false);
    });
    tab.set('active', true);
    later(() => {
      tab.set('rendered', true);
      this.trigger(`opened:${tab.get('identifier')}`);
    }, delay);
  },

  reset() {
    this.set('badges', {});
  },

  subscribe() {
    // TODO switch to pubSub
    this.startPolling();
  },

  unsubscribe() {
    this.stopPolling();
  },

  // TODO remove polling add Pubsub!
  startPolling() {
    this.stopPolling();

    this.updateBadges();
    const interval = setInterval(() => this.updateBadges(), this.pollingIntervalTime);
    this.set('pollingInterval', interval);
  },

  stopPolling() {
    const interval = this.pollingInterval;
    if (interval) {
      clearInterval(interval);
      this.set('pollingInterval', null);
    }
  },

  updateBadges(playSound = true) {
    if (!this.get('state.session.user_id')) {
      return;
    }

    const currentBadges = Object.assign({}, this.badges);

    return this.api.read('myliga', '/communicator_badges')
      .then((badges) => {
        if (JSON.stringify(currentBadges) === JSON.stringify(badges)) {
          return;
        }

        this.set('badges', badges);
      })
      .catch((err) => {
        console.error(err);
      })
      .then(() => scheduleOnce('afterRender', () => {

        // Trigger notification sound if one of the badges is greater that before.
        const { badges } = this;
        playSound = playSound && Object.keys(badges).find((key) => (
          !isNone(currentBadges[key]) && badges[key] > currentBadges[key]
        ));

        if (playSound) {
          this.state.triggerNotificationSound();
        }
      }))
      .catch((err) => {
        console.error(err);
      });
  },

  setNotificationsOpened: on('opened:notifications', function() {
    if (this.get('badges.notifications') > 0) {
      this.api.update('vdc', '/notifications/opened')
        .then(() => this.updateBadges(false));
    }
  })
});
