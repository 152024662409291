import { assert } from '@ember/debug';
import Evented from '@ember/object/evented';
import EmberObject, { get, computed } from '@ember/object';
import RSVP from 'rsvp';
import { later, cancel } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { classify } from '@ember/string';
import { typeOf, isNone } from '@ember/utils';
import { pluralize } from 'ember-inflector';

import { replaceAll, escapeExpStr } from 'ln-ember-toolkit/utils/regexp';
import {
  cleanUrl,
  cleanAppUrl,
  decodeHashPart
} from '../utils/url';
import injectConfig from '../utils/inject-config';

function replaceUrlPlaceholders(url) {
  return url.replace('{host}', window.location.hostname);
}

function stripFeatureBranchName(url) {
  return url.replace(/(-\w+)?(-\d+)(\/?)/, '$3');
}

export default Service.extend(Evented, {

  api: service(),

  state: service(),

  config: injectConfig(),

  store: service(),

  navigations: null,

  currentUrl: null,

  _placeholders: null,

  currentItem: computed('currentUrl', 'navigations.[]', function() {
    return this.getItemFor(this.currentUrl);
  }),

  currentApp: computed('currentUrl', function() {
    return this.getAppFor(this.currentUrl);
  }),

  // This is supposed to be needed in the development only
  // in order to map ln-test URLs provided by API to the locally served apps
  urlReplaces: computed('config.urlReplaces', function() {
    const urlReplaces =  this.get('config.urlReplaces') || {};

    Object.keys(urlReplaces).forEach((key) => {
      urlReplaces[key] = replaceUrlPlaceholders(urlReplaces[key]);
    });

    return urlReplaces;
  }),

  init() {
    this._super(...arguments);

    this.set('_placeholders', {});

    this.state.on('did-logout', () => {
      this.reset();
    });
  },

  reset() {
    this.set('navigations', null);
    this.set('currentUrl', null);
  },

  loadNavigations(force = false) {
    if (!force && this.navigations) {
      return RSVP.resolve();
    }

    return this.api.read('myliga', ['navigations']) // Should also use the Myliga API, but there is a problem on PI prod
      .then((response) => {
        this.set('navigations', this._cleanUpNavigations(response.result));
        return RSVP.resolve();
      });
  },

  isAllowedAppUrl(url) {
    const app = this.getAppFor(url);

    return Boolean(app && !app.get('disabled'));
  },

  loadedAppCheck() {
    if (!this.get('state.appLoaded')) {
      if (this.get('config.disable.loadedAppCheck')) {
        return this.state.setAppLoaded(true);
      }

      cancel(this.lastLoadedAppCheck);

      this.set('lastLoadedAppCheck', later(() => {
        if (!this.get('state.session')) {
          return;
        }
        if (!this.get('state.appLoaded')) {
          if (this.get('config.disable.loadedAppCheckError')) {
            console.error('App not loaded: The App could not be loaded properly.');
            return this.state.setAppLoaded(true);
          }

          this.state.triggerAppError({
            title: 'App not loaded!'.loc(),
            description: 'The App could not be loaded properly.'.loc()
          });
        }
      }, this.get('config.loadedAppCheckTime') || 3000));
    }
  },

  belongsToCurrentApp(url) {

    return this.getItemFor(this._cleanUrl(url)) === this.currentItem;
  },

  belongsToExternalApp(url) {
    const app = this.getAppFor(url);
    return Boolean(app && !app.get('slug'));
  },

  getItemFor(url) {
    if (!url || !this.navigations) {
      return;
    }

    if (this.get('config.allow_feature_branches')) {
      url = stripFeatureBranchName(url);
    }

    return this.navigations.toArray()
      .sort((a, b) => b.url.length - a.url.length)
      .find((nav) => {
        const navUrl = nav.url.replace(new RegExp(/\/?#?\/$/), '');

        return (new RegExp(`${escapeExpStr(navUrl)}($|#|/|\\?)`)).test(url);
      });
  },

  getApps() {
    const apps = (this.get('config.apps') || [])
      .filter((app) => app.url)
      .map((app) => {
        const urlString = replaceUrlPlaceholders(app.url);
        // if the `urlString` is fully qualified URL
        // the second argument is being ignored
        const url = new URL(urlString, location.origin);
        const path = (urlString.startsWith('/')
          ? `${url.pathname}`
          : `${url.host}${url.pathname}`
        );
        const search = url.search || '';
        const hash = url.hash || '';

        // matches incoming URL strings with a proper navigation item config
        const urlMatcher = new RegExp(`/?${escapeExpStr((path + search).replace(/\/$/, ''))}(#|/|$)`);

        // @todo: `url_path` is some workaround mechanism
        // let's make sure we don't have it on API and remove this codepath
        const navItem = (this.navigations || []).find((item) => {
          return urlMatcher.test(item.url);
        });

        const slug = navItem && navItem.url_path
          ? navItem.url_path
          : app.slug;

        return EmberObject.create({
          ...app,
          urlMatcher,
          url,
          path: path.replace(/^\//, '').replace(/\/$/, ''),
          search,
          hash,
          slug
        });
      });

    return apps;
  },

  getAppFor(url) {
    url = this._cleanUrl(url);

    if (this.get('config.allow_feature_branches')) {
      url = stripFeatureBranchName(url);
    }

    return this.getApps().find((app) => {
      return app.urlMatcher.test(url);
    });
  },

  openUrl(url) {
    if (this.belongsToExternalApp(url)) {
      this.set('state.applicationsError', null);
      const newWindow = window.open(url, '_blank');

      // detect popup blocker
      if (!newWindow || newWindow.closed || isNone(newWindow.closed)) {
        this.set('state.applicationsError', 'Popup blocked! Please allow popups.'.loc());
        return false;
      }
      return true;
    }

    if (!this.belongsToCurrentApp(url)) {
      this.state.resetTitle();
      this.state.setAppLoaded(false);
    }

    url = this._cleanUrl(url);

    if (this.setUrl(url)) {
      this.trigger('open-url', url);
      return true;
    }

    return false;
  },

  setUrl(url) {
    this.resetAppError();

    url = this._cleanUrl(url);

    if (!this.isAllowedAppUrl(url)) {
      // eslint-disable-next-line no-console
      console.warn(`Is not an allowed app URL: ${url}`);

      this.state.triggerAppError({
        title: 'Access Denied!'.loc(),
        description: 'The App is not a valid LIGA OS app.'.loc()
      });

      return false;
    }

    if (!this.getItemFor(url)) {
      this.state.triggerAppError({
        title: 'Access Denied!'.loc(),
        description: 'You are missing necessary rights to access this app.'
      });

      return false;
    }

    this.set('currentUrl', url);
    this.trigger('url-did-change', url);

    return true;
  },

  resetAppError() {
    if (this.get('state.appError')) {
      this.state.triggerAppErrorReset();
    }
  },

  isCurrentUrl(url) {
    return this.currentUrl === url;
  },

  isCurrentItem(item) {
    return this.currentUrl === get(item, 'url');
  },

  findFirstItem(items) {
    return items.find((item) => item.url);
  },

  openFirstItem() {
    const { url } = (this.findFirstItem(this.navigations) || {});
    this.openUrl(url);
  },

  openResource(resource, resourceId) {
    assert(
      'Provide resource (string), resourceId (number)',
      this.canOpenResource(resource, resourceId)
    );
    return this._buildResourceUrl(resource, resourceId)
      .then((url) => this.openUrl(url));
  },

  canOpenResource(resource, resourceId) {
    if (resource === 'Message') {
      return true;
    }

    return Boolean(
      !isNone(resourceId) && !isNaN(Number(resourceId))
      && typeOf(resource) === 'string'
      && this._getResourceUrlTemplate(resource)
    );
  },

  _buildResourceUrl(resource, resourceId) {
    return this._loadSegments(resource, resourceId)
      .then(({ resource, segments }) => {
        const urlTemplate = this._getResourceUrlTemplate(resource);
        let url = this._fillPlaceholders(urlTemplate, segments);
        url = this.removeSlug(url);
        url = decodeHashPart(url);
        url = replaceAll(url, this.urlReplaces);

        return RSVP.resolve(url);
      });
  },

  removeSlug(url) {
    const ticketId = this.get('config.allow_feature_branches')
      ? '((-\\w+)?(-\\d+))?'
      : '';

    this.getApps()
      .filter(({ slug }) => slug)
      .forEach((app) => {
        const find = new RegExp(`^(!//?)${escapeExpStr(app.slug)}(${ticketId})($|/(?<appPath>.*|$))`);

        const matches = url.match(find);
        if (matches) {
          const basePath = app.path;
          const protocol = matches[1] || ''; // "!//" or "!/"
          const ticketHandle = matches[2] || '';
          const { appPath } = matches.groups;
          url = `${protocol}${basePath}${ticketHandle}/${app.search}${app.hash}${appPath}`;
        }
      });

    return url;
  },

  addSlug(url) {
    const ticketId = this.get('config.allow_feature_branches')
      ? '((-\\w+)?(-\\d+))?'
      : '';

    this.getApps()
      .filter(({ slug }) => slug)
      .forEach((app) => {
        const basePath = escapeExpStr(app.path);
        const search = escapeExpStr(app.search.replace('?', '|'));
        const find = new RegExp(`^(!//?)${basePath}(${ticketId}/)${search}([|]{2})?/?(?<appPath>.*|$)`);

        const matches = url.match(find);
        if (matches) {
          const protocol = matches[1];
          const ticketHandle = matches[2];
          const { appPath } = matches.groups;

          url = `${protocol}${app.slug}${ticketHandle}${appPath}`;
        }
      });

    return url;
  },

  _fillPlaceholders(url, segments = null) {
    url = url || '';

    this._extractPlaceholders(url).forEach((placeholder) => {
      const segment = segments[placeholder] || '';
      url = url.replace(`:${placeholder}`, segment);
    });

    return url;
  },

  _extractPlaceholders(url) {

    if (!this._placeholders[url]) {
      this._placeholders[url] = (String(url).match(/:([^/\d][^/|&]*)/g) || [])
        .map((placeholder) => placeholder.replace(/^:/, ''));
    }

    return this._placeholders[url];
  },

  _getResourceUrlTemplate(resource) {
    return this.get(`config.resourceUrlTemplates.${classify(resource)}`);
  },

  _loadSegments(resource, resourceId) {
    const func = `_load${classify(resource)}Segments`;

    if (typeOf(this[func]) === 'function') {
      return this[func](resourceId);
    }

    /*
     * All the placeholders in config/targets (resourceUrlTemplates)
     * are lowercase, and since we use 'context_type' (which comes from API capitalized) for
     * segment/placeholder processing, there is an issue when trying to access the segment.
     *
     * API:
     * {
     *  ...
     *  context_type: 'Pin'
     *  context_id: 3147
     *  ...
     * }
     *
     * APP:
     * segment object:
     * {
     *  pin: 3147
     * }
     */
    const resourceVal = resource.toLowerCase();

    return RSVP.resolve({
      segments: { [resourceVal]: resourceId },
      resource
    });
  },

  _loadArticleReviewSegments(articleReviewId) {
    const resource = 'ArticleReview';

    return this.api.read('vdc', ['article_reviews', articleReviewId])
      .then((articleReview) => {
        const segments = {
          project: articleReview.project_id,
          country: articleReview.country_id
        };

        return RSVP.resolve({ resource, segments });
      });
  },

  _loadStoreSegments(storeId) {
    const segments = {};
    return this.api.read('vdc', ['stores', storeId])
      .then((store) => {
        segments.store = store.id;
        segments.client = store.client_id;

        return RSVP.resolve({ segments, resource: 'Store' });
      });
  },

  _loadOrderSegments(orderId) {
    const segments = {};
    return this.api.read('vdc', ['orders', orderId])
      .then((order) => {
        segments.order = order.id;
        return this.api.read('vdc', ['projects', order.project_id]);
      })
      .then((project) => {
        segments.project = project.id;
        return this.api.read('vdc', ['clients', project.client_id]);
      })
      .then((client) => {
        segments.client = client.id;
        return RSVP.resolve({ resource: 'Order', segments });
      });
  },

  _loadAssemblySegments(assemblyId) {
    const segments = {};
    return this.api.read('vdc', ['assemblies', assemblyId])
      .then((assembly) => {
        segments.assembly = assembly.id;
        return this.api.read('vdc', ['projects', assembly.project_id]);
      })
      .then((project) => {
        segments.project = project.id;
        return this.api.read('vdc', ['clients', project.client_id]);
      })
      .then((client) => {
        segments.client = client.id;
        return RSVP.resolve({ resource: 'Assembly', segments });
      });
  },

  _loadProjectSegments(projectId) {
    return this.api.read('vdc', ['projects', projectId])
      .then(function(project) {
        const segments = {
          project: project.get('id'),
          client: project.get('client_id')
        };

        return RSVP.resolve({  resource: 'Project', segments });
      });
  },

  _loadProjectTaskSegments(taskId) {
    const segments = {};

    return this.api.read('vdc', ['tasks', taskId])
      .then((task) => {
        segments.task = task.id;

        return this.api
          .read('vdc', ['projects', task.context_id]);
      })
      .then((project) => {
        segments.project = project.id;
        segments.client = project.client_id;

        return RSVP.resolve({ resource: 'ProjectTask', segments });
      });
  },

  _loadEventTaskSegments(taskId) {
    const segments = {};

    return this.api.read('vdc', ['tasks', taskId])
      .then((task) => {
        segments.task = task.id;

        return this.api
          .read('vdc', ['events', task.context_id]);
      })
      .then((event) => {
        segments.event = event.id;
        segments.client = event.client_id;

        return RSVP.resolve({ resource: 'EventTask', segments });
      });
  },

  _loadMessageSegments(messageId) {
    const segments = {};

    return this.api.read('vdc', ['messages', messageId])
      .then((message) => {
        const contextType = message.context_type;

        segments.message = message.id;

        if (!['Project', 'Event'].includes(contextType)) {
          return RSVP.reject();
        }

        const resource = pluralize(contextType.toLowerCase());

        return this.api.read('vdc', [resource, message.context_id])
          .then((resource) => ({
            resource,
            resourceName: contextType
          }));
      })
      .then(({ resource, resourceName }) => {
        segments[resourceName.toLowerCase()] = resource.id;
        segments.client = resource.client_id;

        return RSVP.resolve({
          segments,
          resource: resourceName === 'Project' ? 'ProjectMessage' : 'EventMessage'
        });
      });
  },

  _cleanUrl(url) {
    let _url = url || '';
    _url = cleanUrl(_url);
    _url = cleanAppUrl(_url);
    _url = replaceAll(_url, this.urlReplaces);

    return _url;
  },

  _cleanUpNavigations(navigations) {
    if (!navigations) { return []; }

    return navigations
      .sortBy('position')
      .map((navigation) => this._cleanUpNavigation(navigation))
      .filter((navigation) => {
        const allowed = this.isAllowedAppUrl(navigation.url);

        if (!allowed) {
          // eslint-disable-next-line no-console
          console.warn('Not an allowed navigation item', navigation);
        }

        return allowed;
      });
  },

  _cleanUpNavigation(navigation) {
    navigation.url = replaceAll(navigation.url, this.urlReplaces);

    return navigation;
  }
});
