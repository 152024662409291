import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { cancel, later } from '@ember/runloop';
import { underscore } from '@ember/string';
import moment from 'moment';
import injectConfig from 'ln-liga-os/utils/inject-config';

export default Component.extend({

  state: service(),

  tagName: 'span',

  classNames: [
    'date-time-component'
  ],

  classNameBindings: [
    'type'
  ],

  date: null,

  type: 'date-time',

  relative: false,

  timer: null,

  config: injectConfig(),

  format: computed('type', function() {

    const { type } = this;

    if (type === 'date') {
      return this.getFormat(type, 'MM-DD-YYYY');
    }

    if (type === 'date-big') {
      return this.getFormat(type, 'DD<\\s\\p\\an>MMM</\\s\\p\\an>');
    }

    if (type === 'date-time') {
      return this.getFormat(type, 'DD-MM-YYYY - HH:mm');
    }

    if (type === 'time') {
      return this.getFormat(type, 'HH:mm');
    }

    return '';
  }),

  dateFormated: computed('date', function() {
    const m = moment(this.date);
    const language = this.state.getLanguage();

    if (this.date && m.isValid()) {
      if (this.relative) {
        if (moment().diff(m, 'hours') < 24) {
          return m.locale(language).fromNow();
        }
      }

      return m.format(this.format);
    }
  }),

  didInsertElement() {
    this._super(...arguments);
    this.updateRelative();
  },

  willDestroyElement() {
    this._super(...arguments);
    this.cancelTimer();
  },

  getFormat(type, defaultValue) {
    const language = this.state.getLanguage();
    return this.get(`config.formats.${language}.${underscore(type)}`)
      || this.get(`config.format.${underscore(type)}`)
      || defaultValue;
  },

  updateRelative() {
    if (this.isDestroyed) { return; }

    const m = moment(this.date);

    if (this.relative && m.isValid()) {

      let timeoutSec = 15;
      if (moment().diff(m, 'hours') > 0) {
        timeoutSec = 300;
      } else if (moment().diff(m, 'days') >= 1) {
        return;
      }

      const timer = later(() => {
        this.notifyPropertyChange('date');
        this.updateRelative();
      }, timeoutSec * 1000);

      this.set('timer', timer);
    }
  },

  cancelTimer() {
    const { timer } = this;

    if (timer) {
      cancel(timer);
    }
  }
});
