/* eslint ligadigital/ember-model-version-attribute: Off */

import { get } from '@ember/object';
import Model from 'ember-data/model';

export function initialize() {
  /**
    @class Model
    @namespace DS
  */
  Model.reopen({

    /**
     * Set a given attribute to its `defaultValue`.
     * If no `defaultValue` was specified in the attribute, it will default to `null`.
     * If the given attribute can't be found on the model, it will return undefined.
     *
     * @param {string} attributeName
     * @returns {*} the value that was set or undefined if the attribute could not be reset
     */
    resetToDefault(attributeName) {
      const attributes = get(this.constructor, 'attributes');
      const attribute = attributes.get(attributeName);

      if (!attribute) {
        return;
      }

      const { defaultValue } = attribute.options;

      return this.set(
        attributeName,
        typeof defaultValue === 'function' ? defaultValue() : defaultValue
      );
    }

  });
}

export default {
  name: 'ln-ember-data-models',
  after: 'ember-data',
  initialize
};
