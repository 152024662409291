import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({

  state: service(),

  beforeModel() {
    if (this.get('state.session')) {
      return this.transitionTo('/!');
    }
  },

  model({ token }) {
    return {
      token
    };
  },

  activate() {
    // In some very rare cases appLoaded is set to true when the user lands on the this screen
    // That leads to a missing class name in the application view which messes up the reset pass layout
    this.state.setProperties({
      appLoaded: false,
      appLocation: null
    });
    this.state.resetTitle();
  }

});
