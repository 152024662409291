import Component from '@ember/component';
import { inject as service } from '@ember/service';
import injectConfig from '../utils/inject-config';

export default Component.extend({

  state: service(),

  clientTheme: service(),

  classNames: [
    'startup-mask-component'
  ],

  classNameBindings: [
    'show',
    'state.session:logged-in',
    'clientTheme.className',
    'state.appLoaded:app-loaded:app-not-loaded'
  ],

  config: injectConfig()
});
