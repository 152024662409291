import Component from '@ember/component';
import { none } from '@ember/object/computed';
import { isNone } from '@ember/utils';
import { observer } from '@ember/object';
import { next, scheduleOnce } from '@ember/runloop';

export default Component.extend({
  classNames: [
    'communicator-tab-base-component'
  ],

  classNameBindings: [
    'shouldFadein',
    'isContentVisible',
    'isLoading'
  ],

  isContentVisible: false,

  shouldFadein: none('isLoading'),

  updateIsInitiallyVisible: observer('isLoading', function() {
    scheduleOnce('afterRender', () => next(() => {
      if (isNone(this.isLoading)) {
        this.set('isContentVisible', true);
      } else if (!this.isContentVisible && !this.isLoading) {
        this.set('isContentVisible', true);
      }
    }));
  }),

  didInsertElement() {
    this._super(...arguments);

    this.updateIsInitiallyVisible();
  }
});
