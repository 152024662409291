import Component from '@ember/component';

export default Component.extend({

  classNames: ['form-condition-component'],

  classNameBindings: ['isMet'],

  label: null,

  isMet: false

});
