import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ItemBase from './item-base';

export default ItemBase.extend({

  api: service(),

  classNames: [
    'contact-list-item-project-component'
  ],

  project: alias('item.project'),

  actions: {
    openProjectMail(event) {
      event.stopPropagation();
      const { project } = this;
      const query = {
        project: project.id,
        show_in_communicator_contact_list: true
      };

      this.api.read('myliga', ['liga3000_contacts', query]).then((response) => {
        const addresses = (response.result || []).map((contact) => {
          return contact.email;
        });

        window.open(`mailto:${addresses.join(',')}`);
      });
    }
  },
  click() {
    this.onProjectClick(this.item);
  }

});
