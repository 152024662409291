import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { set, observer, computed } from '@ember/object';
import GuideConfig from 'ln-ember-guide-tooltips/utils/guide-config';

export default Controller.extend({

  state: service(),

  clientTheme: service(),

  navigation: service(),

  appError: null,

  guidesActive: false,

  init() {
    this._super(...arguments);

    this.set('appError', this.get('state.appError'));
    this.state
      .on('app-error', (error) => this.set('appError', error))
      .on('app-error-reset', () => this.set('appError', null));
  },

  navigationGuides: computed('clientTheme.isDefault', function() {
    const isDefault = this.get('clientTheme.isDefault');

    const name = this.get('state.user.forename');
    const team = isDefault ? 'VDC Team' : 'LIGA-OS-Team';

    return [
      GuideConfig.create({
        target: '#communicator-user-image',
        content: 'Hi %@!<br>The communicator and your apps are now located behind your user image. <span class="foot">Yours %@</span>'.loc(name, team),
        nextOn: 'mouseenter',
        side: 'bottom',
        step: 0,
        class: 'app-switcher-guide'
      }),
      GuideConfig.create({
        target: '#communicator-user-image',
        content: '<span class="light">New:</span> Settings'.loc(),
        nextOn: 'click',
        closeSide: 'left',
        step: 1
      }),
      GuideConfig.create({
        target: '#tab-item-applications',
        content: '<span class="light">New:</span> Apps'.loc(),
        nextOn: 'click',
        closeSide: 'left',
        step: 1
      })
    ];
  }),

  toggleGuides: observer('state.communicatorIsOpen', function(state) {
    if (this.get('state.communicatorIsOpen')) {
      state = false;
    }

    const guides = this.navigationGuides;
    set(guides, '1.isVisible', state);
    set(guides, '2.isVisible', state);
  }),

  actions: {
    tabVisibilityChanged(state) {
      if (!this.guidesActive) {
        return;
      }

      this.toggleGuides(state);
    }
  }
});
