import $ from 'jquery';
import { compare } from '@ember/utils';
import { inject as service } from '@ember/service';
import { observer, computed } from '@ember/object';
import { throttle, scheduleOnce } from '@ember/runloop';
import Evented from '@ember/object/evented';
import TabBaseComponent from './tab-base';
import MediaEvents from 'ln-ember-toolkit/mixins/media-events';

const {
  assign
} = Object;

export default TabBaseComponent.extend(MediaEvents, Evented, {

  communicator: service(),

  state: service(),

  store: service(),

  navigation: service(),

  classNames: [
    'communicator-tab-notifications-component',
    'list',
    'notifications-list'
  ],

  classNameBindings: [
    'filterClass',
    'isLoading'
  ],

  lastRequest: null,

  hasMore: true,

  notifications: null,

  filter: 'current',

  pagingLimit: 20,

  searchTerm: '',

  isLoading: true,

  close: () => {},

  filterClass: computed('filter', function() {
    return `filter-${this.filter}`;
  }),

  filterObserver: observer('filter', function() {
    throttle(this, this.loadData, true, 100);
  }),

  didInsertElement() {
    this._super(...arguments);

    $(this.element).on('scroll', (event) => this.onScroll(event));
    scheduleOnce('afterRender', () => this.loadData(true));
  },

  willDestroyElement() {
    this._super(...arguments);

    $(this.element).off('scroll');
  },

  actions: {
    openNotification(notification) {

      if (notification.get('link')) {
        this.navigation.openUrl(notification.get('link'));
        this.close();
        return;
      }

      const resource = notification.get('context_type');
      const resourceId = notification.get('context_id');

      if (this.navigation.canOpenResource(resource, resourceId)) {
        this.navigation.openResource(resource, resourceId);
        this.close();
      }
    },

    searchValueDidChange(value) {
      this.set('searchTerm', value);
      this.loadData(true);
    },

    filter(filterKey) {
      this.set('filter', filterKey);
    },

    option(optionKey, notification) {

      let prop;
      switch (optionKey) {
        case 'toggleRead':    prop = 'read_state'; break;
        case 'toggleFlag':    prop = 'flagged';    break;
        case 'toggleArchive': prop = 'archived';   break;

        default:
          return;
      }

      notification.toggleProperty(prop);
      notification.save();
    }
  },
  loadData(reload = false) {

    if (!this.get('state.session.user_id')) {
      return;
    }

    if (reload) {
      this.set('notifications', []);
      this.set('hasMore', true);
    }

    if (!this.hasMore) {
      return;
    }

    const query = this.createQuery({
      paging_offset: this.get('notifications.length') || 0,
      paging_limit: this.pagingLimit
    });
    const lastRequestHash = JSON.stringify(query);

    if (this.isLoading && this.lastRequestHash === lastRequestHash) {
      return;
    }

    this.set('lastRequestHash', lastRequestHash);
    this.set('isLoading', true);

    const request = this.store
      .query('vdc/notification', query)
      .then((notifications) => {
        if (this.isDestroyed || request !== this.lastRequest) {
          return;
        }

        this.notifications.pushObjects(
          notifications.toArray()
            .sort((a, b) => compare(b.get('datetime'), a.get('datetime')))
        );

        this.set('hasMore', this.pagingLimit === notifications.get('meta.result_count'));
      })
      .catch((error) => console.error(error))
      .finally(() => {
        if (this.isDestroyed || request !== this.lastRequest) { return; }

        this.set('isLoading', false);
      });

    this.set('lastRequest', request);
  },

  createQuery(add = {}) {
    const { filter } = this;
    const query = {};

    switch (filter) {
      case 'current':
        assign(query, { archived: false, current: true }); break;
      case 'flagged':
        assign(query, { archived: false, flagged: true }); break;
      case 'unread':
        assign(query, { archived: false, read_state: false }); break;
      case 'archive':
        assign(query, { archived: true,  current: false }); break;
    }

    const term = this.getProperSearchTerm();
    if (term !== '') {
      assign(query, { search_text: term });
    }

    return assign(query, add);
  },

  getProperSearchTerm() {
    let term = this.searchTerm || '';
    const numChars = term.replace(/\s+/g, '').length; // without whitespace
    if (numChars <= 2) {
      term = '';
    }
    return term;
  },

  updateUnreadCountBadge() {
    this.communicator.updateBadges(false);
  },

  onScroll(event) {
    const target = $(event.target);
    const scrollBottom = target.scrollTop() + target.height();
    const scrollHeight = target.prop('scrollHeight');

    if ((scrollBottom + (scrollHeight * 0.25)) >=  scrollHeight) {
      // reached end of list
      this.loadData();
    }
  }

});
