import Component from '@ember/component';

export default Component.extend({

  classNames: ['check-icon-component'],

  classNameBindings: ['isCorrect'],

  isCorrect: null

});
