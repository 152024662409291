import FilterBase from './filter-base';

export default FilterBase.extend({
  classNames: [
    'communicator-filter-contacts-component'
  ],

  defaultFilter: 'all',

  filters: null,

  init() {
    this.set('filters', [
      { key: 'all',    label: 'All'.loc() },
      { key: 'urgent', label: 'Urgent'.loc() },
      { key: 'today',  label: 'Today'.loc() },
      { key: 'coming', label: 'Upcoming'.loc() }
    ]);

    this._super(...arguments);
  }
});
