import Component from 'ln-ember-contact-menu/components/contact-menu';
import { inject as service } from '@ember/service';

export default Component.extend({

  navigation: service(),

  state: service(),

  actions: {
    openProfile() {
      const { userId } = this;

      const url = this.navigation.removeSlug(`/myliga/#/?user=${userId}`);
      this.navigation.openUrl(url);
      this.state.hideClickCatcher();
    }
  }
});
