import { get, set, observer, computed } from '@ember/object';
import { assign } from '@ember/polyfills';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import TabBaseComponent from './tab-base';
import MediaEvents from 'ln-ember-toolkit/mixins/media-events';
import injectTheme from 'ln-ember-config-utils/utils/inject-theme';

export default TabBaseComponent.extend(MediaEvents, {

  api: service(),

  rights: service(),

  flashMessages: service(),

  classNames: [
    'communicator-tab-contacts-component'
  ],

  // Controller content
  filter: null,

  contacts: null,

  isLoading: false,

  editMode: false,

  canEditList: false,

  activeList: null,

  detailsFor: null,

  searchText: '',

  showBirthday: false,

  perPage: 50,

  offset: 0,

  theme: injectTheme(),

  formattedContacts: computed('contacts.[]', function() {
    const formattedContacts = [];

    if (this.filter === 'projects' || this.filter === 'lists') {
      return this.contacts;
    }

    /**
     * Sorting is done on the API side. We are just making sure each
     * contact falls into right "company group", since contacts from 1
     * company may cover 2 different pages.
     */
    (this.contacts || []).forEach((contact) => {
      const formattedContact = {
        detailsOpen: false,
        type: 'contact',
        contact
      };

      // If contact has no company, push it to the array
      if (!contact.company_name) {
        formattedContacts.push(formattedContact);
      } else {
        const companyIndex = formattedContacts.findIndex(({ text }) => text === contact.company_name);
        const nextSubheadIndex = formattedContacts.findIndex((item, index) => item.type === 'subhead' && index > companyIndex);
        /*
         * If there is another "group/subhead" after this company, add this contact right before that group
         * i.e. "push" it at the end of this "group".
         */
        const spliceIndex = nextSubheadIndex > -1 ? nextSubheadIndex - 1 : formattedContacts.length;

        // If the company exists in the array, add contact to that group
        if (companyIndex > -1) {
          formattedContacts.splice(spliceIndex, 0, formattedContact);
        } else {
          /**
           * Otherwise, add the company to the array, AND add the contact right after
           */
          formattedContacts.push({
            type: 'subhead',
            text: contact.company_name
          }, formattedContact);
        }
      }
    });

    return formattedContacts;
  }),

  allExpanded: computed('searchText', function() {
    return Boolean(this.searchText && this.get('searchText.length') > 0);
  }),

  resetAndLoadMore: observer('filter', function() {
    this.setProperties({
      contacts: [],
      offset: 0,
      requestedForOffset: null,
      clientsLoaded: false,
      listsLoaded: false,
      editMode: false
    });

    this.send('loadMore');
  }),

  init() {
    this._super(...arguments);

    this.resetAndLoadMore();
    this.initRights();
  },

  actions: {
    loadMore() {
      if (this.isLoading || this.requestedForOffset === this.offset) {
        return;
      }

      const { filter } = this;
      if (filter === 'all') {
        return this.loadAll();
      } else if (filter === 'projects') {
        return this.loadProjects();
      } else if (filter === 'lists') {
        return this.loadLists();
      }
    },

    searchInputChanged(value) {
      this.set('searchText', value);
      this.resetAndLoadMore();
    },

    linkTo(to) {
      window.location = to;
    },

    toggleClient(client) {
      set(client, 'isOpen', !get(client, 'isOpen'));
      const { isOpen } = client;
      const contacts = this.contacts.filter((contact) => {
        if (!isOpen
          && contact.type === 'project'
          && contact.project.contact_group_id === client.client.id) {
          set(contact, 'isOpen', false);
          return false;
        }

        if (isOpen === false && contact.type === 'contact') {
          return false;
        }

        return true;
      });

      this.set('contacts', contacts);

      if (isOpen) {
        this.insertChilds(client, 'projects');
      }
    },

    toggleProject(project) {
      set(project, 'isOpen', !get(project, 'isOpen'));
      const { isOpen } = project;
      const contacts = this.contacts.map((contact) => {
        if (contact.type === 'client') {
          return contact;
        }

        if (contact !== project) {
          set(contact, 'isOpen', false);
        }

        return contact;
      }).filter((contact) => {
        return contact.type === 'client' || contact.type === 'project';
      });

      this.set('contacts', contacts);

      if (isOpen) {
        this.insertChilds(project, 'contacts');
      }
    },

    toggleMailingList(list) {
      this.toggleProperty('editMode');

      if (list) {
        this.set('activeList', list.list);
        this.set('activeListCopy', assign({}, list.list));
      } else if (this.activeList) {
        const prevList = this.activeList;
        const listCopy = this.activeListCopy;

        for (const prop in prevList) {
          set(prevList, prop, listCopy[prop]);
        }
      }
    },

    saveMailingList() {
      let promise = RSVP.reject();
      if (!this.get('activeList.id')) {
        promise = this.api.create('myliga', 'email_distributors', this.activeList).then((list) => {
          const { contacts } = this;
          const addChild = contacts.findBy('type', 'add_list');
          const position = contacts.indexOf(addChild);

          this.contacts.insertAt(position, {
            isOpen: false,
            type: 'mailing_list',
            list
          });
        });
      } else {
        const listId = this.get('activeList.id');
        promise = this.api.update('myliga', ['email_distributors', listId], this.activeList);
      }

      promise.then((/* resp */) => {
        this.set('editMode', false);
      }).catch((error) => {
        console.error(error);
      });
    },

    deleteMailingList() {
      const list = this.activeList;

      this.api.delete('myliga', ['email_distributors', list.id]).then(() => {
        const listToRemove = this.contacts.find((listObject) => {
          return listObject.list === list;
        });

        this.contacts.removeObject(listToRemove);
        this.set('editMode', false);
      });
    },

    addMailingList() {
      const newList = {
        id: null,
        version: null,
        name: null,
        description: null,
        email: null
      };
      this.set('activeList', newList);
      this.set('editMode', true);
    },

    openProjectMail(project) {

      const query = {
        project: project.id,
        show_in_communicator_contact_list: true
      };

      this.api.read('myliga', ['liga3000_contacts', query]).then((response) => {
        const addresses = (response.result || []).map((contact) => {
          return contact.email;
        });

        window.open(`mailto:${addresses.join(',')}`);
      });
    }
  },
  initRights() {
    this.rights.can('UPDATE', 'EmailDistributor').then((permission) => {
      this.set('canEditList', permission);
    });
  },

  addSubhead(to, name) {
    to.push({
      type: 'subhead',
      text: name
    });
  },

  loadAll() {
    const query = {
      paging_offset: this.offset,
      paging_limit: this.perPage,
      search_text: this.searchText,
      show_in_communicator_contact_list: true,
      active: true
    };

    this.set('isLoading', true);
    this.api.read('myliga', ['liga3000_contacts', query]).then((response) => {
      if (this.isDestroyed) { return; }

      this.set('requestedForOffset', this.offset);
      this.incrementProperty('offset', response.meta.count);
      this.contacts.pushObjects(response.result);
    })
      .then(() => this.checkListLength())
      .finally(() => {
        if (this.isDestroyed) { return; }
        this.set('isLoading', false);
      });
  },

  loadProjects() {
    if (this.clientsLoaded) {
      return;
    }

    this.set('isLoading', true);

    const query = { projects_search_text: this.searchText, active_project: true };
    const maxExpanded = 20;

    this.api.read('myliga', ['contact_groups', query])
      .then((response) => {
        if (this.isDestroyed) { return; }

        let counter = 0;
        const clients = (response.result || []).map((group) => {
          counter++;
          return {
            isOpen: this.allExpanded && counter <= maxExpanded,
            client: group,
            type: 'client',
            projects: null
          };
        });
        this.beginPropertyChanges();

        if (this.allExpanded) {
          const groupsToExpand = (clients || []).slice(0, maxExpanded);
          const groupIds = groupsToExpand.mapBy('client.id');

          this.loadProjectsForGroups(groupIds).then((projects) => {

            groupsToExpand.forEach((group) => {
              const projectsForGroup = projects.result
                .filterBy('contact_group_id', get(group, 'client.id'));

              group.projects = projectsForGroup.map((project) => {
                return {
                  isOpen: false,
                  type: 'project',
                  project,
                  contacts: null
                };
              });

              this.insertChilds(group, 'projects');
            });

          });
        }

        this.set('requestedForOffset', this.offset);
        this.incrementProperty('offset', response.meta.count);

        this.set('contacts', clients);
        this.set('isLoading', false);
        this.set('clientsLoaded', true);

        this.endPropertyChanges();

        this.checkListLength();
      })
      .catch((error) => console.error(error.stack || error))
      .finally(() => {
        if (this.isDestroyed) { return; }
        this.set('isLoading', false);
      });

    return;
  },

  loadProjectsForGroups(groups) {
    const query = {
      contact_group: groups,
      search_text: this.searchText,
      active: true
    };

    return this.api.read('myliga', ['liga3000_projects', query]);
  },

  loadLists() {
    if (this.listsLoaded) {
      return;
    }

    this.set('isLoading', true);
    const { contacts } = this;

    this.api.read('myliga', 'email_distributors')
      .then((response) => {
        const addList = contacts.findBy('type', 'add_list');
        contacts.removeObject(addList);

        const lists = (response.result || []).map((list) => {
          return {
            isOpen: false,
            type: 'mailing_list',
            list
          };
        });

        contacts.pushObjects(lists);

        if (this.canEditList) {
          contacts.pushObject({
            type: 'add_list'
          });
        }
        this.set('listsLoaded', true);
      })
      .catch((error) => {
        this.flashMessages.addMessage('error', 'Unable to load email distributors');
        console.error(error);
      })
      .then(() => this.checkListLength()).finally(() => this.set('isLoading', false));
  },

  insertChilds(childsFor, what) {
    const { contacts } = this;
    const loader = { type: 'loader' };
    let offset = contacts.indexOf(childsFor) + 1;

    if (childsFor[what] === null) {
      contacts.insertAt(offset, loader);

      if (what === 'projects') {

        this.loadProjectsForGroups([childsFor.client.id]).then((response) => {

          childsFor[what] = (response.result || []).map((project) => {
            return {
              isOpen: false,
              type: 'project',
              project,
              contacts: null
            };
          });

          contacts.removeObject(loader);
          this.insertChilds(childsFor, what);
        });

        return;

      } else if (what === 'contacts') {
        const query = {
          project: childsFor.project.id,
          show_in_communicator_contact_list: true,
          active: true
        };
        const detailsOpen = false;

        this.api.read('myliga', ['liga3000_contacts', query]).then((response) => {
          childsFor[what] = (response.result || []).map((contact) => {
            return {
              detailsOpen,
              contact,
              type: 'contact'
            };
          });

          contacts.removeObject(loader);
          this.insertChilds(childsFor, what);
        });
        return;

      }
    }

    (childsFor[what] || []).forEach((child) => {
      contacts.insertAt(offset, child);
      offset++;
    });

    this.set('contacts', contacts);
  },

  checkListLength() {
    scheduleOnce('afterRender', () => {
      const contactsLength = this.get('contacts.length');

      if (contactsLength <= 0) {
        this.formattedContacts.pushObject({
          type: 'no_results'
        });
      }
    });
  }

});
