import Component from '@ember/component';

export default Component.extend({

  classNames: ['loading-spinner-component'],

  classNameBindings: ['color'],

  color: null

});
