import FilterBase from './filter-base';

export default FilterBase.extend({
  classNames: [
    'communicator-filter-notifications-component'
  ],

  defaultFilter: 'current',

  filters: null,

  init() {
    this.set('filters', [
      { key: 'current', label: 'Inbox'.loc() },
      { key: 'flagged', label: 'Flagged'.loc() },
      { key: 'unread',  label: 'Unread'.loc() },
      { key: 'archive', label: 'Archive'.loc() }
    ]);

    this._super(...arguments);
  }
});
