import { alias } from '@ember/object/computed';
import ItemBase from './item-base';

export default ItemBase.extend({
  classNames: [
    'contact-list-item-absence-component'
  ],

  contact: alias('item.contact')
});
